import './browser-feature-detection'
import VehicleDiagnosticCodes from './vehicle-diagnostic-codes'
import VehicleDiagnosticSymptoms from './vehicle-diagnostic-symptoms'
import DiagnosticExploration from './diagnostic-exploration'
import VehicleSessionList from './vehicle-session-list'
import AdminUsers from './admin-users'
import DiagnosticSession from './diagnostic-session'
import Index from './index'
import AdminScanTools from './admin-scan-tools'
import AdminRelatedContentInquiry from './admin-related-content-inquiry'
import AdminBIExport from './admin-bi-export'
import AdminJobManager from './admin/admin-job-manager'
import SettingsHistory from './settings-history'
import RelatedContentDialog from './related-content-dialog'
import RelatedReferenceDialog from './related-reference-dialog'
import ImportDtcPriorityList from './import-dtc-priority-list'
import ImportEcuCrossRef from './import-ecu-cross-ref'
import ImportModelMatrix from './import-model-matrix'
import NissanUpload from './nissan-upload'
import ConnectedVehicle from './connected-vehicle'
import Settings from './settings'
import Nav from './nav'
import BatteryInquirer from './battery-inquirer'
import BasicInspection from './basic-inspection'
import VehicleSessionInfo from './vehicle-info-config'
import AdminAnalytics from './admin-analytics-download'
import Roles from './admin/roles'
import axios from 'axios'
import './ui-kit'
import logger from './logger-service'

import $ from 'jquery'

require('!style-loader!css-loader!bootstrap-datepicker/dist/css/bootstrap-datepicker.css')
require('!style-loader!css-loader!bootstrap-select/dist/css/bootstrap-select.css')
require('!style-loader!css-loader!datatables.net-bs/css/dataTables.bootstrap.css')
require('!style-loader!css-loader!p-loading/dist/css/p-loading.min.css')
require('!style-loader!css-loader!lightslider/dist/css/lightslider.css')
require('!style-loader!css-loader!magnific-popup/dist/magnific-popup.css')

switch (process.env.THEME) {
  case 'bobcat':
    require('../styles/main-bobcat.scss')
    break
  default:
    require('../styles/main.scss')
}

require('jquery.panzoom')
//jquery-validation
require('jquery-validation')

// require('blueimp-file-upload');
/* The jQuery UI widget factory, can be omitted if jQuery UI is already included */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/vendor/jquery.ui.widget.js')
/* The Iframe Transport is required for browsers without support for XHR file uploads */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/jquery.iframe-transport.js')
/* The basic File Upload plugin */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/jquery.fileupload.js')
/* The File Upload processing plugin */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/jquery.fileupload-process.js')
/* The File Upload validation plugin */
require('imports-loader?define=>false&exports=>false!blueimp-file-upload/js/jquery.fileupload-validate.js')

require('datatables.net')
require('datatables.net-bs')
require('bootstrap-datepicker')
require('bootstrap-select')
require('jquery-match-height')
require('p-loading')
require('bluebird')
require('lightslider')
require('magnific-popup')

/* Hack for IE10 on Jquery Ajax Cache   */
$.ajaxSetup({ cache: false })

/* Hack for IE10 on ES6 Promise support that is used by axios */

if (!window.Promise) {
  window.Promise = require('es6-promise').Promise
}

const TweddleId = {
  Index: Index,
  DiagnosticExploration: DiagnosticExploration,
  DiagnosticSession: DiagnosticSession,
  RelatedContentDialog: RelatedContentDialog,
  RelatedReferenceDialog: RelatedReferenceDialog,
  VehicleDiagnosticCodes: VehicleDiagnosticCodes,
  VehicleDiagnosticSymptoms: VehicleDiagnosticSymptoms,
  VehicleSessionList: VehicleSessionList,
  AdminUsers: AdminUsers,
  AdminScanTools: AdminScanTools,
  AdminRelatedContentInquiry: AdminRelatedContentInquiry,
  ImportDtcPriorityList: ImportDtcPriorityList,
  ImportRelatedContent: ImportDtcPriorityList, //reusing ImportDtcPriorityList
  ImportEcuCrossRef: ImportEcuCrossRef,
  ImportModelMatrix: ImportModelMatrix,
  UploadHelp: ImportDtcPriorityList, //reusing ImportDtcPriorityList
  ConnectedVehicle: ConnectedVehicle,
  Settings: Settings,
  Nav: Nav,
  BatteryInquirer: BatteryInquirer,
  BasicInspection: BasicInspection,
  AdminJobManager: AdminJobManager,
  VehicleSessionInfo: VehicleSessionInfo,
  SettingsHistory: SettingsHistory,
  Roles: Roles,
  AdminAnalytics: AdminAnalytics
}

global.TweddleId = TweddleId

module.exports = TweddleId
