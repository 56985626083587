import {
  disableAndClean,
  enable,
  fromCodeLabelsToOptions,
  refreshStateCascade,
  prepButtonsForLoading,
  refreshSelectpicker,
  contextPath,
  clearMessages,
  successAlert
} from './ui-utils'
import $ from 'jquery'
import _ from 'lodash'
import axios from 'axios'
import { errorAlert, handleError, clearLoadingButtons, setLoading } from './ui-utils'
import mustache from 'mustache'
import { showConfigurationSelectionForTree } from './select-ecu-configuration-modal'
import logger from './logger-service'
import feedback from './feedback-service'
import FeedbackConstants from './feedback/feedbackConstants'
import FeedbackStorage, { getEventData, storeEventData } from './feedback/feedbackStorage'

const addDtcForm = '#addDtcForm',
  editDtcForm = '#editDtcForm',
  inputECU = 'select#inputECU',
  inputDTC = 'select#inputDtc',
  inputState = 'select#inputState',
  buttonAdd = '.buttonAdd',
  inputUpdateECU = 'select#inputUpdateECU',
  inputUpdateDTC = 'select#inputUpdateDtc',
  inputUpdateState = 'select#inputUpdateState',
  inputHiddenDTCId = 'input#dtcId',
  buttonUpdate = '.buttonUpdate',
  buttonCancelUpdate = '.buttonCancelUpdate'

let selectTreeModalTemplate = null,
  dtcListTemplate = null

const {
  events: { DTC_UPDATE, DTC_ADD, DTC_REMOVE, DTC_START_DIAGNOSTIC },
  fields: { DTC, ECU, STATUS }
} = FeedbackConstants

function editDtc(source) {
  feedback.track(DTC_UPDATE, {
    [DTC]: $(source).data('dtc'),
    [ECU]: $(source).data('ecu'),
    [STATUS]: $(source).data('state')
  })

  let id = $(source).data('dtc-id')
  editMode($(source))
}

function removeDtc(source) {
  let id = $(source).data('dtc-id')

  feedback.track(DTC_REMOVE, {
    [DTC]: $(source).data('dtc'),
    [ECU]: $(source).data('ecu'),
    [STATUS]: $(source).data('state')
  })

  additionMode()

  $.post(
    `${window.location.pathname}/dtcs/remove`,
    {
      id: id
    },
    function () {
      loadList()
    }
  )
}

function startPendingHistorySession(source) {
  let sessionId = $(location).attr('pathname').split('/').pop(),
    ecu = $(source).data('ecu')
  setLoading()
  axios
    .get(contextPath(`/diagnostic-session/${sessionId}/pending-history/tree`), { params: { ecu: ecu } })
    .then(res => {
      redirectToStartDiagnosticSession(sessionId, _.first(res.data).DecisionTree)
    })
    .catch(handleError)
}

function redirectToStartDiagnosticSession(sessionId, treeId, dtcId) {
  let params = (dtcId && `?dtcId=${dtcId}`) || ''
  window.location.href = contextPath(`/diagnostic-session/${sessionId}/trees/${treeId}/start${params}`)
}

// Start Session performs a look up on Empolis to see if there are more than 1 trees
// available for a ecu/dtc combination. If there are multiple choices, the user
// is presented with the options and moves forward with their choice.
function startSession(source, recommended = true) {
  let sessionId = $(location).attr('pathname').split('/').pop(),
    dtc = $(source).data('dtc'),
    ecu = $(source).data('ecu'),
    dtcId = $(source).data('dtc-id')

  feedback.track(DTC_START_DIAGNOSTIC, {
    [DTC]: dtc,
    [ECU]: ecu,
    [STATUS]: $(source).data('state')
  })

  additionMode()
  axios
    .get(contextPath(`/diagnostic-session/${sessionId}/trees`), { params: { dtc: dtc, ecu: ecu } })
    .then(res => {
      if (recommended)
        if (_.size(res.data) > 1) {
          // axios.post(contextPath(`/diagnostic-session/${sessionId}/recommended`));

          // If there are multiple DTC trees, let user choose which tree to use
          renderTreeSelectionTemplate(res, sessionId, dtcId)
        } else {
          redirectToStartDiagnosticSession(sessionId, res.data[0].DecisionTree, dtcId)
        }

      clearLoadingButtons()
    })
    .catch(err => {
      clearLoadingButtons()
      if (err.response && err.response.status === 404) {
        errorAlert(err.response.data)
        logger.error(err.response.data)
      } else {
        errorAlert(err.message)
        logger.error(err)
      }
    })
}

function renderTreeSelectionTemplate(res, sessionId, dtcId) {
  if (hasUniqueConfigNbr(res)) {
    logger.debug('Trees data', res.data)
    $('#selectTreeModalTarget').html(
      mustache.render(selectTreeModalTemplate, {
        trees: res.data,
        sessionId: sessionId,
        dtcId: dtcId
      })
    )
    $('#selectTreeModal').modal()
  } else
    showConfigurationSelectionForTree(sessionId, res.data, (decisionTree, configurationSelected) =>
      selectConfiguration(sessionId, dtcId, decisionTree, configurationSelected)
    )
}

function hasUniqueConfigNbr(res) {
  let isECUorTCM = _.includes(['TCM', 'ECM'], _.get(_.first(res.data), 'ECU'))
  return isECUorTCM ? true : _.filter(res.data, { ConfigName: res.data[0].ConfigName }).length === res.data.length
}

function selectTree(sessionId, dtcId) {
  setLoading()
  let decisionTree = $('input[name=decisionTree]:checked', $('#formSelectTree')).val()
  window.location.href = contextPath(`/diagnostic-session/${sessionId}/trees/${decisionTree}/start?dtcId=${dtcId}`)
}

function selectConfiguration(sessionId, dtcId, decisionTreeId, configurationName) {
  window.location.href = contextPath(
    `/diagnostic-session/${sessionId}/trees/${decisionTreeId}/start?dtcId=${dtcId}&configNbr=${configurationName}`
  )
}

function getSessionId() {
  return $(location).attr('pathname').split('/').pop()
}

function addDtc(event) {
  clearMessages()
  event.preventDefault()
  let ecuVal = $(inputECU).val()
  let ecu = $('#inputECU option')
    .filter(function () {
      return $(this).html() === ecuVal
    })
    .attr('ecuid')
  let dtc = $(inputDTC).val().toUpperCase()
  let state = $(inputState).val()

  feedback.track(DTC_ADD, {
    [DTC]: dtc,
    [ECU]: ecu,
    [STATUS]: state
  })

  let posting = $.post(`${window.location.pathname}/dtcs/add`, {
    ecu: ecu,
    dtc: dtc,
    state: state
  })
    .error(function (error) {
      clearLoadingButtons()
      errorAlert(error.responseJSON.error)
      logger.error('Failed to add DTC')
    })
    .done(function (vs) {
      clearLoadingButtons()
      loadList()

      // disableAndClean input fields after adding new DTC
      $(inputECU).val('')
      $(inputState).val($(inputState).find('option').first().val())
      disableAndClean(inputDTC, buttonAdd)
      $('.selectpicker').selectpicker('refresh')
      clearMessages()
      successAlert(`Record successfully added. ECU: ${ecu}, DTC: ${dtc} and Status: ${state}.`)
    })
}

function checkDtcs() {
  let sessionId = $(location).attr('pathname').split('/').pop()
  $('.startSessionButton').each(function () {
    if (!$(this).hasClass('checked')) {
      const dtc = $(this).data('dtc')
      const ecu = $(this).data('ecu')
      axios
        .get(contextPath(`/diagnostic-session/${sessionId}/check-tree`), {
          params: { dtc, ecu }
        })
        .then(res => {
          const relatedContentButton = $(`a.btn-related-content[data-ecu="${ecu}"][data-dtc="${dtc}"]`)
          const data = res.data
          if (relatedContentButton && (data.Comment || data.Memo_PDF || data.Memo_Image)) {
            relatedContentButton.removeClass('hidden')
            relatedContentButton.attr('data-tree-id', data.DecisionTree)
          }
          $(this).addClass('checked')
          $('.startSession').removeClass('disabled')
        })
        .catch(() => {
          $(this).attr('disabled', true)
        })
    }
  })
}

function loadList() {
  $.get(`${window.location.pathname}/dtcs`).done(res => {
    let isNormalProcedureMode = res.vehicleSession.procedureMode !== 'PENDING_HISTORY',
      modifiedDtcList = _.map(res.dtcList, (d, index) => {
        logger.info(`DTC List Item [${index}]`, d)
        if (!d.ecuName) {
          d.ecuName = d.ecu
        }
        d.parentId = res.vehicleSession._id
        d.parentYear = res.vehicleSession.year
        d.parentMake = res.vehicleSession.make
        d.parentModel = res.vehicleSession.model
        d.parentEngineCode = res.vehicleSession.engineCode
        d.parentTcmCode = res.vehicleSession.tcmCode
        d.isNormalProcedureMode = isNormalProcedureMode
        d.rank = d.rank * 100
        d.isDtcRankingsOn = d.isDtcRankingsOn && res.dtcList.length !== 1
        return d
      })

    $('#dtcListTemplateTarget').html(
      //
      mustache.render(dtcListTemplate, {
        vehicleSession: res.vehicleSession,
        hasDtcs: !_.isEmpty(res.vehicleSession.dtcList),
        dtcList: modifiedDtcList,
        recommendedDtc: res.recommendedDtc,
        isNormalProcedureMode: isNormalProcedureMode,
        showRecommendedDtc: res.recommendedDtc && isNormalProcedureMode
      })
    )

    clearLoadingButtons()
    checkDtcs()
  })
}

function loadTemplate() {
  selectTreeModalTemplate = $('#selectTreeModalTemplate').html()
  mustache.parse(selectTreeModalTemplate)

  dtcListTemplate = $('#dtcListTemplate').html()
  mustache.parse(dtcListTemplate)
}

function additionMode() {
  $('#addDtcForm').removeClass('hidden')
  $('#editDtcForm').addClass('hidden')

  $('tr.edit').removeClass('edit')
}

function editMode(source) {
  let dtcId = source.data('dtc-id'),
    dtc = source.data('dtc'),
    ecu = source.data('ecu'),
    state = source.data('state')

  clearMessages()

  fetch('dtc', dtc, 'ecus').then(result => {
    $('tr.edit').removeClass('edit')
    source.closest('tr').addClass('edit')

    logger.log('Fetch result', result)

    $('option', inputUpdateECU).remove()
    $('option', inputUpdateDTC).remove()

    fromCodeLabelsToOptions(inputUpdateECU)(result)
    $(inputUpdateECU).selectpicker('val', ecu)
    $(inputUpdateECU).selectpicker('refresh')

    fromCodeLabelsToOptions(inputUpdateDTC, dtc)([{ code: dtc, label: dtc }])
    $(inputUpdateDTC).selectpicker('val', dtc)
    $(inputUpdateDTC).selectpicker('refresh')

    $(inputUpdateState).selectpicker('val', state)

    $(inputHiddenDTCId).val(dtcId)

    $(addDtcForm).addClass('hidden')
    $(editDtcForm).removeClass('hidden')
  })
}

function updateDtc(event) {
  clearMessages()
  let sessionId = $(location).attr('pathname').split('/').pop(),
    dtc = $(inputUpdateDTC).val(),
    ecu = $(inputUpdateECU).val(),
    dtcId = $(inputHiddenDTCId).val(),
    state = $(inputUpdateState).val()

  axios
    .put(`/vehicle-diagnostic-codes/${sessionId}/dtc/${dtcId}`, { dtc: dtc, ecu: ecu, state: state })
    .then(() => successAlert(`Record successfully updated. ECU: ${ecu}, DTC: ${dtc} and Status: ${state}.`))
    .then(additionMode)
    .then(loadList)
    .catch(err => {
      errorAlert(err.response.data.error)
    })
  event.stopPropagation()
}

function setEvents() {
  $(inputECU).change(refreshStateCascade(enableDtcCodes, inputDTC, refreshSelectpicker(inputDTC), buttonAdd))
  $(inputDTC).change(refreshStateCascade(enable(buttonAdd), buttonAdd))
  $(buttonCancelUpdate).click(additionMode)
  $(buttonUpdate).click(updateDtc)
  $(buttonAdd).click(function () {})
}

function load() {
  prepButtonsForLoading()
  loadTemplate()

  additionMode()
  setEvents()

  // Wire / Unwire Non-Recommended Modal to confirm selection
  $('#nonRecommendedDtcModal').on('show.bs.modal', function (e) {
    $(this)
      .find('.btn-ok')
      .click(function () {
        $('#nonRecommendedDtcModal').modal('hide')
        startSession(e.relatedTarget, false)
      })
  })
  $('#nonRecommendedDtcModal').on('hidden.bs.modal', clearLoadingButtons)

  $('#nonRecommendedDtcModal').on('hide.bs.modal', function (e) {
    $(this).find('.btn-ok').unbind('click')
  })

  disableAndClean(inputDTC, buttonAdd)
  $(addDtcForm).submit(addDtc)

  loadList()

  // Default to the first ECU
  if ($(`${inputECU} > option`).length > 1) $(inputECU).prop('selectedIndex', 1).trigger('change')
}

function fetch() {
  return axios
    .get(contextPath(['/vehicle-diagnostic-codes'].concat(_.toArray(arguments)).join('/')))
    .then(resp => resp.data)
}

function enableDtcCodes(ecu, selectedDtc) {
  fetch('ecu', encodeURIComponent(ecu), 'dtcs').then(function (result) {
    fromCodeLabelsToOptions(inputDTC, selectedDtc)(result)
    refreshSelectpicker(inputDTC)()
  })
}

function bind() {
  bindPopover()
  $(window).resize(function () {
    bindPopover()
  })
}

function bindPopover() {
  $('.info-popover').each(function () {
    if ($(this).width() > $(this).parent().width()) {
      $(this).popover({ placement: 'left' })
    }
  })
}

module.exports = {
  load: load,
  selectTree: selectTree,
  startSession: startSession,
  startPendingHistorySession: startPendingHistorySession,
  removeDtc: removeDtc,
  editDtc: editDtc,
  bind: bind
}
