import $ from 'jquery'
import _ from 'lodash'
import { processLinks } from './link-processor'

// noinspection ES6UnusedImports It is used. Otherwise, crashing the app
import trumbowyg from 'trumbowyg'

$.trumbowyg.svgPath = '/images/icons.svg'

import axios from 'axios'
import mustache from 'mustache'
import Promise from 'bluebird'
import { toggleStatus } from './battery-inquirer'
import {
  clearLoadingButtons,
  clearMessages,
  contextPath,
  disableiOSBodyScrollOverModal,
  errorAlert,
  handleError,
  setLoading,
  successAlert
} from './ui-utils'
import jScrollPane from './jscrollpane'
import logger from './logger-service'
import feedback from './feedback-service'
import FeedbackConstants from './feedback/feedbackConstants'
import { getEventData } from './feedback/feedbackStorage'
const finishNewSolutionModal = '#finishNewSolutionModal',
  finishExistingSolutionModal = '#finishExistingSolutionModal',
  pauseModal = '#pauseModal',
  closedNoTroubleFoundModal = '#closedNoTroubleFoundModal',
  cancelModal = '#cancelModal'

;(function IIFE() {
  let sessionId,
    scantoolSessionId,
    attributes,
    treeId,
    nodeId,
    vehicleSession,
    diagnosticSession,
    sessionRating,
    settings,
    isSolution,
    isVerification,
    pathname = window.location.pathname.replace(/\/\s*$/, ''),
    isSessionFinish,
    parsedTemplates,
    dtcSelected,
    updateWindowHistory,
    currentSolution
  const {
    events: {
      SESSION_CANCEL,
      SESSION_PAUSE,
      SESSION_SELECT_SOLUTION,
      SESSION_FINISH,
      SESSION_START_ANOTHER,
      SESSION_EXPORT_SUMMARY,
      SESSION_SELECT_HISTORY
    },
    fields: { TYPE, PATH, SOLUTION, META, ITERATE_LOG_ITEMS }
  } = FeedbackConstants

  const language = 'en'
  let attachmentsTemplate = null,
    questionsTemplate = null,
    testStepsModalTemplate = null,
    solutionsTemplate = null,
    historyTemplate = null

  function sendVehicleOptions(vehicleoptions) {
    let option = vehicleoptions.shift()
    // const toyota = require('toyota-scan-tool-lib').default;
    toyota
      .getOptions(option.optionKey, option.optionId)
      .then(response => {
        if (response.options && vehicleoptions.length > 0) {
          sendVehicleOptions(vehicleoptions)
        } else {
          toggleStatus('disabled', 'enabled', 'Connected', '0.0')
        }
      })
      .catch(error => {
        logger.error('Failed to connect to Toyota', error)
        errorAlert('Failed to establish connection with scan tool')
        toggleStatus('enabled', 'disabled', 'Disconnected', '0.0')
      })
  }

  function verifyDtc(dtc, ecu, ecuDetails) {
    return new Promise((resolve, reject) => {
      // const toyota = require('toyota-scan-tool-lib').default;
      if (!ecuDetails.some(test => test.Ecuid === ecu)) {
        reject()
        return
      }
      toyota
        .checkEcuForDtc(ecu)
        .then(response => {
          if (!response.dtcdetails) {
            reject()
          }
          response.dtcdetails.forEach(readDtc => {
            if (dtc === readDtc.dtcCode) {
              resolve({ verified: true })
            }
          })
          resolve({ verified: false })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  function isValidEcu(ecu, ecuDetails) {
    return ecuDetails.some(test => test.Ecuid === ecu)
  }

  function readPids(pids, ecu) {
    return new Promise((resolve, reject) => {
      // const toyota = require('toyota-scan-tool-lib').default;
      toyota
        .getPidsValues(ecu, pids)
        .then(response => {
          return resolve(response.ecu_pids)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  function readPid(pid, ecuDetails) {
    return new Promise((resolve, reject) => {
      if (isValidEcu(pid.ecu, ecuDetails)) {
        // const toyota = require('toyota-scan-tool-lib').default;
        toyota
          .getPidsValues(pid.ecu, [pid.id])
          .then(response => {
            pid = response.ecu_pids.find(ecu_pid => {
              return ecu_pid.pidid === pid.id
            })
            if (!pid) {
              return reject()
            }
            return resolve(pid.value)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        return reject('Invalid ECU')
      }
    })
  }

  function connectToyotaScanTool(vs) {
    if (vs.vehicleOptions.length === 0) {
      return
    }
    // const toyota = require('toyota-scan-tool-lib').default;
    toyota
      .connectToDenso()
      .then(response => {
        sendVehicleOptions(vs.vehicleOptions)
      })
      .catch(error => {
        errorAlert('Failed to connect to scan tool')
        logger.error(error)
      })
  }

  function load(vs, ds, empolis, s, d, history) {
    feedback.track('Start Guide', {
      DTCs: ds.dtcs?.map(dtc => `${dtc.dtc} [Description: ${dtc.description}]`),
      Engine: vs.engineName,
      Locale: vs.metadata?.locale,
      Procedure: ds.procedureName,
      'Procedure Description': ds.empolisShortDescription,
      'Procedure Mode': ds.procedureMode,
      Region: vs.metadata?.region,
      'Session State': ds.state,
      'Session Type': vs.sessionType,
      'User Dealer': vs.metadata?.orgName,
      'User ID': vs.metadata?.username,
      'User Level': vs.metadata?.level,
      'User Name': `${vs.metadata?.firstName} ${vs.metadata?.lastName}`
    })
    logger.debug('VS Data', vs)
    diagnosticSession = ds
    vehicleSession = vs
    scantoolSessionId = vs.scanToolSessionId
    settings = s
    dtcSelected = d
    updateWindowHistory = true
    $('.dropdown-toggle').dropdown()

    parsedTemplates = _.reduce(
      $('script.template'),
      (memo, template) => {
        var html = $(`#${template.id}`).html()
        mustache.parse(html)
        memo[$(template).attr('id')] = html
        return memo
      },
      {}
    )

    attachmentsTemplate = $('#attachmentsTemplate').html()
    mustache.parse(attachmentsTemplate)

    testStepsModalTemplate = $('#testStepsModalTemplate').html()
    mustache.parse(testStepsModalTemplate)

    bind()

    displayData(empolis)

    disableiOSBodyScrollOverModal($('#testStepsModal'))

    bindHistoryEvents()
  } //end of load

  function e(element) {
    return $(`<${element}></${element}>`)
  }

  function bindValidation(buttonId, formId, modalId, submitCallback) {
    $(buttonId).click(() => {
      if (!$(formId).valid()) return
      $(modalId).modal('hide')
      $(formId).submit()
    })
    $(formId).validate()
    $(formId).submit(submitCallback)
  }

  function bindWysiwyg() {
    let prop = {
      autogrow: false,
      removeformatPasted: true,
      btns: [['strong', 'em'], ['removeformat']]
    }

    $('#ds-solution-comment-2').trumbowyg(prop)
    $('#ds-technician-suggestion-2').trumbowyg(prop)
    $('#ds-key-observations-2').trumbowyg(prop)
    $('#ds-possible-actions-2').trumbowyg(prop)
    $('#ds-diagnostic-content-2').trumbowyg(prop)

    $('#ds-solution-comment').trumbowyg(prop)
    $('#ds-technician-suggestion').trumbowyg(prop)
    $('#ds-key-observations').trumbowyg(prop)
    $('#ds-possible-actions').trumbowyg(prop)
    $('#ds-diagnostic-content').trumbowyg(prop)

    $('#ds-solution-comment-3').trumbowyg(prop)
    $('#ds-technician-suggestion-3').trumbowyg(prop)
    $('#ds-key-observations-3').trumbowyg(prop)
    $('#ds-possible-actions-3').trumbowyg(prop)
    $('#ds-diagnostic-content-3').trumbowyg(prop)
  }

  function bind() {
    bindIntermittentIncident()

    let overrideState
    $('.state').on('change', function () {
      overrideState = this.value
    })

    function submitCallback(name, state) {
      return function (event) {
        event.preventDefault()
        let logs = [
          {
            name: 'Session State',
            value: overrideState || state
          }
        ]

        let textInputs = $(`.capture-text.${name}`)
        textInputs.each((index, value) => {
          logs.push({
            name: $(value).attr('log-name'),
            value: $(value).val()
          })
        })

        let radioInputs = $(`.capture-radio.${name}:checked`)
        radioInputs.each((index, value) => {
          logs.push({
            name: $(value).attr('log-name'),
            value: $(value).val()
          })
        })

        if (sessionRating) {
          logs.push({
            name: 'Session Rating',
            value: sessionRating
          })
        }
        let vehicleSessionInfo = {
          state: overrideState || state,
          rating: sessionRating,
          logs: logs
        }
        updateSessionState(vehicleSessionInfo, 'finish', `${pathname}/finished`)
      }
    }

    bindValidation('#buttonFinishNewSolution', '#formFinishNewSolution', finishNewSolutionModal, function (event) {
      const cb = submitCallback('finishNewSolution', 'new-solution')
      const itemsEl = $('#historyTemplateTarget div.history-item').toArray()
      itemsEl.shift(1)
      const items = itemsEl.map((it, index) => {
        const stepNumber = `Step ${index + 1}`
        const question = $(it).find('.history-question').text().trim().substring(0, 50)
        const answer = $(it).find('.history-answer').text().trim()
        return { stepNumber, answer, question }
      })
      const answers = items.map(i => {
        return i.answer ? i.answer : 'step_forward'
      })
      feedback.track(SESSION_FINISH, {
        [TYPE]: getEventData()[PATH],
        ['Steps']: items,
        ['Answers']: answers,
        ['Procedure']: diagnosticSession?.procedureName,
        ['Procedure Description']: diagnosticSession?.empolisShortDescription,
        Engine: vehicleSession.engineName,
        Locale: vehicleSession.metadata?.locale,
        Region: vehicleSession.metadata?.region,
        'Session Type': vehicleSession.sessionType,
        'User Dealer': vehicleSession.metadata?.orgName,
        'User ID': vehicleSession.metadata?.username,
        'User Level': vehicleSession.metadata?.level,
        'User Name': `${vehicleSession.metadata?.firstName} ${vehicleSession.metadata?.lastName}`
      })
      cb(event)
    })

    bindValidation(
      '#buttonFinishWithSolution',
      '#formFinishWithSolution',
      finishExistingSolutionModal,
      submitCallback('finishWithSolution', 'existing-solution')
    )

    bindValidation(
      '#buttonClosedNoTroubleFound',
      '#formClosedNoTroubleFound',
      closedNoTroubleFoundModal,
      submitCallback('closedNoTroubleFound', 'closed-no-trouble-found')
    )

    bindValidation(
      '#buttonPause',
      '#pauseSessionForm',
      pauseModal, //
      function (event) {
        event.preventDefault()
        var vehicleSessionInfo = {
          state: 'pause',
          comment: $('#ds-solution-comment-pause').val(),
          reason: $('#ds-reason-pause').val()
        }
        feedback.track(SESSION_PAUSE, {
          [TYPE]: getEventData()[PATH],
          Procedure: diagnosticSession?.procedureName,
          'Procedure Description': diagnosticSession?.empolisShortDescription,
          Engine: vehicleSession.engineName,
          Locale: vehicleSession.metadata?.locale,
          Comment: vehicleSessionInfo.comment,
          Reason: vehicleSessionInfo.reason,
          Region: vehicleSession.metadata?.region,
          'Session Type': vehicleSession.sessionType,
          'User Dealer': vehicleSession.metadata?.orgName,
          'User ID': vehicleSession.metadata?.username,
          'User Level': vehicleSession.metadata?.level,
          'User Name': `${vehicleSession.metadata?.firstName} ${vehicleSession.metadata?.lastName}`
        })
        updateSessionState(vehicleSessionInfo, 'pause', `${pathname}/paused`)
      }
    )
    bindValidation(
      '#buttonCancel',
      '#cancelSessionForm',
      cancelModal, //
      function (event) {
        event.preventDefault()

        var vehicleSessionInfo = {
          state: 'canceled',
          comment: $('#ds-solution-comment-cancel').val()
        }

        feedback.track(SESSION_CANCEL, {
          [TYPE]: getEventData()[PATH],
          Procedure: diagnosticSession?.procedureName,
          'Procedure Description': diagnosticSession?.empolisShortDescription,
          Engine: vehicleSession.engineName,
          Locale: vehicleSession.metadata?.locale,
          Region: vehicleSession.metadata?.region,
          'Session Type': vehicleSession.sessionType,
          'User Dealer': vehicleSession.metadata?.orgName,
          'User ID': vehicleSession.metadata?.username,
          'User Level': vehicleSession.metadata?.level,
          'User Name': `${vehicleSession.metadata?.firstName} ${vehicleSession.metadata?.lastName}`
        })
        updateSessionState(vehicleSessionInfo, 'cancel', '/')
      }
    )

    bindValidation('#feedbackButton', '#feedbackForm', '#dsFeedback', function (event) {
      event.preventDefault()
      sendFeedback($('#feedbackComment').val())
    })

    bindValidation('#pidButton', '#pidForm', '#dsPid', function (event) {
      event.preventDefault()
      let pidValue = $('#pidInput').val()
      sendPidLog(pidValue, 'pid_data_log')
      $('a.pid-data').html(pidValue)
    })

    bindWysiwyg()

    $('#solveButton').click(function () {
      const solveForm = $('#confirmSolveForm')
      const { solutionTree, solutionNode } = solveForm.data()
      $.ajax({
        url: `${pathname}/solve`,
        type: 'POST',
        dataType: 'json',
        data: {
          treeId: solutionTree,
          nodeId: solutionNode
        }
      })
        .then(data => {
          displayData(data)
          $('#dsConfirmSolveDialog').modal('hide')
        })
        .catch(err => {
          logger.error(err)
        })
      updateWindowHistory = true
    })

    $(document).on('click touchstart', '.rating span', function () {
      sessionRating = _.toInteger($(this).attr('data-value'))
      sessionRating = typeof sessionRating !== 'undefined' ? sessionRating : ''
      var rateArray = _.range(1, sessionRating + 1)
      _(rateArray).forEach(o => $('span[data-value=' + o + ']').html('&#9733;'))
      _([1, 2, 3, 4, 5])
        .difference(o => o, rateArray)
        .forEach(o => $('span[data-value=' + o + ']').html('&#9734;'))
    })

    $('#answers').on('click', 'button.select-answer', function (e) {
      sendAnswer($(this).attr('data-answer-value'), $('#question-description').text())
    })

    $('#modal-tab a.tab').click(function (e) {
      e.preventDefault()
      $(this).tab('show')
    })

    $('#modal-tab a.tab').first().tab('show')

    $('#modal-tab a.tab').on('shown.bs.tab', function (e) {
      $($(e.target).attr('href') + '-footer').addClass('active')
      $($(e.relatedTarget).attr('href') + '-footer').removeClass('active')
    })

    $(document).bind('drop dragover', e => {
      e.preventDefault()
    })

    bindDragAndDrop($(finishNewSolutionModal))
    bindDragAndDrop($(finishExistingSolutionModal))
    bindDragAndDrop($(closedNoTroubleFoundModal))
    bindDragAndDrop($(pauseModal))
    bindDragAndDrop($(cancelModal))

    $('.collapse-expert').on('click', function () {
      $('.panel-collapse').collapse('hide')
    })

    // $(finishNewSolutionModal);
    // $(finishExistingSolutionModal).on('show.bs.modal', loadAttachments($(finishExistingSolutionModal)));
    // $(pauseModal).on('show.bs.modal', loadAttachments($(pauseModal)));
    // $(cancelModal).on('show.bs.modal', loadAttachments($(cancelModal)));
  }

  function bindDragAndDrop(parent) {
    parent.on('show.bs.modal', loadAttachments(parent))

    $('.dragndrop-zone .attach-file', parent).click(e => {
      e.stopPropagation()
      $('input.uploadFile', parent).click()
    })

    $('.dragndrop-zone .cancel', parent).click(showAttachedFiles)

    function showDragOptions() {
      $('.dragndrop-zone .drag-panel', parent).hide()
      $('.dragndrop-zone  .dragging-over-file', parent).show()
    }

    function showDragFailed(message) {
      $('.dragndrop-zone .drag-panel', parent).hide()
      $('.dragndrop-zone .upload-failed', parent).show()
      $('.dragndrop-zone h4', parent).html(message)
    }

    function showAttachedFiles() {
      clearDropZoneTimeOut()
      $('.dragndrop-zone .drag-panel', parent).hide()
      $('.dragndrop-zone .attached-files', parent).show()
    }

    function showUploading() {
      clearDropZoneTimeOut()
      $('.dragndrop-zone .drag-panel', parent).hide()
      $('.dragndrop-zone .uploading-file', parent).show()
    }

    function clearDropZoneTimeOut() {
      if (window.dropZoneTimeout) {
        clearTimeout(window.dropZoneTimeout)
        window.dropZoneTimeout = null
      }
    }

    $('input.uploadFile', parent)
      .fileupload({
        url: `${pathname}/attach`,
        dataType: 'json',
        dropZone: $('.dragndrop-zone', parent),
        paramName: 'file',
        singleFileUploads: false,
        done: (e, data) => {
          diagnosticSession = _.get(data.response(), 'jqXHR.responseJSON', diagnosticSession)
          loadAttachments(parent)()
          showAttachedFiles()
        },
        fail: (e, data) => {
          showDragFailed(_.get(data.response(), 'jqXHR.responseJSON.error.message', data.errorThrown))
        }
      })
      .bind('fileuploaddragover', () => {
        if (!window.dropZoneTimeout) {
          showDragOptions()
        } else {
          clearTimeout(window.dropZoneTimeout)
        }

        //hack for time out

        window.dropZoneTimeout = setTimeout(function () {
          showAttachedFiles()
        }, 1000)
      })
      .bind('fileuploadstart', (e, data) => {
        showUploading()
      })
      .bind('fileuploaddrop', (e, data) => {
        showUploading()
        $('.uploadingFileName', parent).html(_.first(data.files).name)
      })
      .bind('fileuploadprogress', (e, data) => {
        let progress = parseInt((data.loaded / data.total) * 100, 10)
        $('.uploadindFile .progress-bar', parent).width(`${progress}%`)
        $('.uploadingProgressStatus', parent).html(`Uploading (${Math.trunc(data.total / 1000)}kb) - ${progress}%`)
      })
  } //end bind

  function rebind() {
    $('.nextQuestion').click(() => {
      let answer = $('input[name=radioAnswer]:checked').val()
      if (answer) {
        sendAnswer(answer, $('#dataQuestionText').html())
      } else {
        errorAlert('Scroll down to answer the question in the step before clicking “NEXT QUESTION”')
      }
      updateWindowHistory = true
    })
    $('.continue').click(function () {
      let answer = $(this).data('default-answer')
      if (answer) {
        sendAnswer(answer, $('#dataQuestionText').html())
      }
      updateWindowHistory = true
    })
    $('.redo-dtc-list').click(function () {
      let answer = $(this).data('default-answer')
      sendAnswer('', $('#dataQuestionText').html(), restartNewDiagnosticSession)
    })

    $('#possibleCausesTemplateTarget li.solution').on('click', function () {
      const tree = $(this).data('solution-tree')
      const node = $(this).data('solution-node')
      const solution = $(this).find('.solution-text').text()

      $('#confirmSolveForm').data('solution-tree', tree).data('solution-node', node)
      feedback.track(SESSION_SELECT_SOLUTION, {
        [TYPE]: SOLUTION,
        [SOLUTION]: solution,
        [META]: { tree, node },
        Procedure: diagnosticSession?.procedureName,
        'Procedure Description': diagnosticSession?.empolisShortDescription,
        Engine: vehicleSession.engineName,
        Locale: vehicleSession.metadata?.locale,
        Region: vehicleSession.metadata?.region,
        'Session Type': vehicleSession.sessionType,
        'User Dealer': vehicleSession.metadata?.orgName,
        'User ID': vehicleSession.metadata?.username,
        'User Level': vehicleSession.metadata?.level,
        'User Name': `${vehicleSession.metadata?.firstName} ${vehicleSession.metadata?.lastName}`
      })

      // NOTE: Commenting out the modal invocation at the request of Bobcat to disable Solutions; not removing it
      // entirely at this time however, as they may want to re-enable it after field-testing
      // $('#dsConfirmSolveDialog').modal('show')
    })

    $('.button-repair').on('click', function () {
      let ecu = dtcSelected.ecu,
        dtc = dtcSelected.dtc,
        treeId = $(this).data('solution-tree'),
        nodeId = $(this).data('solution-node'),
        text = $(this).data('solution-text')
      $(this).html('<div class="spinner"></div>')
      return sendSolution(treeId, nodeId)
        .then(() => verify(ecu, dtc, treeId, nodeId, text))
        .then(data => displayData(data))
        .catch(handleError)
    })

    $('a.thumbnail').click(function () {
      //  loadImage($(this).find('img').attr('src'), parent);
      $('#test-steps-text').fadeOut(function () {
        $('#image-view').fadeIn()
      })
    })

    $('#escalateToSalesForce').on('click', e => {
      let sessionId = $(e.target).data('session-id')
      let url = `/diagnostic-session/${sessionId}/escalate`
      axios.get(url).then(response => {
        let sfUrl = response.data.url
        let vehicleSessionInfo = {
          state: 'escalated',
          rating: null,
          logs: [
            {
              name: 'Session State',
              value: 'escalated'
            }
          ]
        }
        feedback.track('Escalate to Salesforce', {
          'Salesforce URL': sfUrl,
          Procedure: diagnosticSession?.procedureName,
          'Procedure Description': diagnosticSession?.empolisShortDescription,
          Engine: vehicleSession.engineName,
          Locale: vehicleSession.metadata?.locale,
          Region: vehicleSession.metadata?.region,
          'Session Type': vehicleSession.sessionType,
          'User Dealer': vehicleSession.metadata?.orgName,
          'User ID': vehicleSession.metadata?.username,
          'User Level': vehicleSession.metadata?.level,
          'User Name': `${vehicleSession.metadata?.firstName} ${vehicleSession.metadata?.lastName}`
        })
        updateSessionState(vehicleSessionInfo, 'finish', `${pathname}/finished`, true)
        window.open(sfUrl, '_blank')
      })
    })

    bindImageZoom()
    bindScanFetchData()
  }

  function bindScanFetchData() {
    let removeSpinner = source => source.find('.anchor-spinner-wrapper').detach(),
      addSpinner = source => {
        if (!$(source).find('.anchor-spinner-wrapper').length) {
          $('<div class="anchor-spinner-wrapper"><div class="spinner"></div></div>').appendTo(source)
        }
      }
    clearMessages()
    $('a.fetch-data-toyota').click(function () {
      addSpinner($(this))
      let question = $(this).data('scan-expected-answer')
      let command = $(this).data('scan-command')
      switch (command) {
        case 'idk':
          // NOTE: The only idk case right now is misfire_count
          // We did this when we didn't want to figure out a generic way to implement
          // counting how many cylinders had misfires
          readPids([321, 322, 323, 324], 234)
            .then(response => {
              let misfiring_cylinders = 0
              response.forEach(ecu_pid => {
                if (parseInt(ecu_pid.value) > 0) {
                  misfiring_cylinders++
                }
              })
              if (misfiring_cylinders > 2) {
                autoAnswer('Yes')
              } else {
                autoAnswer('No')
              }
              removeSpinner($(this))
            })
            .catch(error => {
              logger.error(error)
              removeSpinner($(this))
              errorAlert('Error Reading PIDs')
            })
          break
        case 'dtc':
          let ecu = parseInt(question.split(':')[0])
          let dtc = question.split(':')[1]
          let answer = 'No'
          // verifyDtc('P1628', 236, vehicleSession.ecuDetails).then(response => {
          verifyDtc(dtc, ecu, vehicleSession.ecuDetails)
            .then(response => {
              if (response.verified) {
                answer = 'Yes'
              }
              autoAnswer(answer)
              removeSpinner($(this))
            })
            .catch(error => {
              logger.error(error)
              removeSpinner($(this))
              errorAlert('Error Verifying DTC')
            })
          break
        case 'pid':
          readPid(question, vehicleSession.ecuDetails)
            .then(response => {
              sendPidLog(response, 'pid_data_log')
              $('a.pid-data').html(response)
              removeSpinner($(this))
              let answer = 'No'
              switch (question.op) {
                case 'COMPARE_IN_RANGE':
                  if (response >= question.min && response <= question.max) {
                    answer = 'Yes'
                  }
                  autoAnswer(answer)
                  break
                case 'COMPARE_MAX':
                  if (response <= question.max) {
                    answer = 'Yes'
                  }
                  autoAnswer(answer)
                  break
                case 'COMPARE_MIN':
                  if (response > question.min) {
                    answer = 'Yes'
                  }
                  autoAnswer(answer)
                  break
                case 'AVAIL':
                  if (response > 0) {
                    answer = 'Yes'
                  }
                  autoAnswer(answer)
                  break
                default:
                  break
              }
            })
            .catch(error => {
              logger.error(error)
              removeSpinner($(this))
              errorAlert('Error Reading PID')
            })
          break
      }
    })
    $('a.fetch-data').click(function () {
      var source = $(this),
        expectedAnswer = $(this).data('scan-expected-answer'),
        command = source.data('scan-command'),
        url = `/scan-inquiry/${scantoolSessionId}/${command}`

      addSpinner(source)

      return axios
        .get(url)
        .then(response => {
          // console.log('Final Response', response);
          let autoAnswerAnswer = getAutoAnswerAnswer(command, expectedAnswer, response.data)
          autoAnswer(autoAnswerAnswer)
          // write logs for fetch data
          axios
            .post(contextPath(`/diagnostic-session/log?empolisSessionId=${encodeURI(sessionId)}`), {
              command: command,
              dataCall: `${command}:${expectedAnswer}`,
              answer: autoAnswerAnswer,
              treeId: treeId,
              nodeId: nodeId,
              logType: 'auto_answer_success'
            })
            .catch(error => logger.error(error))

          removeSpinner(source)
        })
        .catch(err => {
          autoAnswer('')
          removeSpinner(source)
          errorAlert(`${_.get(err, 'response.data.error.message')}`)
        })

      //http://localhost:3000
    })

    function autoAnswer(answer) {
      let loweredAnswer = _.toLower(answer),
        getRadio = value => $(_.find($('input[name=radioAnswer]'), item => value === $(item).val().toLowerCase())),
        yesRadio = getRadio('yes'),
        noRadio = getRadio('no'),
        resetWithCacheValue = radio => {
          radio.siblings('span').html(radio.attr('original-value'))
          radio.removeAttr('disabled')
          radio.prop('checked', false)
        },
        cacheAnswerValue = radio => {
          if (radio.attr('original-value')) return
          radio.attr('original-value', radio.siblings('span').html())
        },
        selectAnswer = radioOn => {
          radioOn.prop('checked', true)
          radioOn.siblings('span').append(' &nbsp &#10003;')
        }

      cacheAnswerValue(yesRadio)
      cacheAnswerValue(noRadio)
      resetWithCacheValue(yesRadio)
      resetWithCacheValue(noRadio)

      if (loweredAnswer === 'yes') selectAnswer(yesRadio, noRadio)
      if (loweredAnswer === 'no') selectAnswer(noRadio, yesRadio)
    }

    function getAutoAnswerAnswer(question, value, vehicleMessage) {
      // console.log(question, value, vehicleMessage);

      if (!(vehicleMessage && vehicleMessage.dtcs)) {
        return undefined
      }

      switch (question) {
        case 'mode4':
        case 'dtc-any':
          return _.isEmpty(vehicleMessage.dtcs) ? 'No' : 'Yes'
        case 'dtc':
          return _.some(vehicleMessage.dtcs, dtc => `${dtc.ecu}:${dtc.dtc}` === value) ? 'Yes' : 'No'
        default:
          return undefined
      }
    }
  } //end of bindScanFetchData

  function bindImageZoom() {
    let parent = $('#image-view')
    $('.image-panning', parent)
      .panzoom({
        $zoomIn: $('#zoom-in', parent),
        $zoomOut: $('#zoom-out', parent)
        // Pan only when the scale is greater than minScale
        //panOnlyWhenZoomed: true,
      })
      .panzoom('reset')
    $('.btn-back', parent).click(function () {
      $('#image-view').fadeOut(function () {
        $('#test-steps-text').fadeIn()
      })
    })
  }

  function loadAttachments(parent) {
    return function () {
      var attachmentsTarget = parent.find('.attachments-target')
      attachmentsTarget
        .html(
          mustache.render(attachmentsTemplate, {
            attachments: diagnosticSession.attachments
          })
        )
        .find('button.remove')
        .click(removeAttachment(parent))
      attachmentsTarget.find('.attached-files').addClass('scroll-box')
      setTimeout(jScrollPane.refresh, 200)
    }
  }

  function removeAttachment(parent) {
    return function () {
      var id = $(this).data('identifier')
      setLoading()
      $.ajax({
        url: `${pathname}/attachments/${id}`,
        type: 'DELETE',
        dataType: 'json',
        success: function (data) {
          clearLoadingButtons()
          diagnosticSession = data
          loadAttachments(parent)()
        },
        fail: function (err) {
          clearLoadingButtons()
        }
      })
    }
  }

  function removeTags(content) {
    var reg = /<\w*>|<\/\w*>/g
    return (content || '').replace(reg, '')
  }

  function restartNewDiagnosticSession() {
    //setLoading();
    axios
      .post(contextPath(`/diagnostic-session/${vehicleSession._id}/pending-history/finish`))
      .then(res => {
        window.location.href = contextPath(`/vehicle-diagnostic-codes/${vehicleSession._id}`)
      })
      .catch(handleError)
  }

  function setupGallery(selector) {
    $(selector).lightSlider({
      gallery: false,
      pager: false,
      item: 4,
      loop: false,
      slideMargin: 5,
      thumbItem: 9
    })

    $(selector).fadeIn()
    $(selector).magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true
      }
    })
  }

  function postProcess(data) {
    let finishButtons = $('#finishSessionButton, #qFinishSessionButton')
    let solutionButtons = $('#verifySolutionButton, #qVerifySolutionButton')

    finishButtons.attr(
      'data-target',
      data.currentVerifiedSolution
        ? finishExistingSolutionModal
        : isSessionFinish
        ? closedNoTroubleFoundModal
        : finishNewSolutionModal
    )

    data.currentVerifiedSolution && (isVerification || isSessionFinish) && $('#finishSessionButton').trigger('click')

    solutionButtons.unbind('click').bind('click', function (e) {
      $(e.target).html('<div class="spinner"></div>')
      verify($(e.target).data('ecu'), $(e.target).data('dtc'), treeId, nodeId, data.dataQuestion.text)
        .then(displayData)
        .catch(handleError)
    })

    if (isSolution && data.canRequireVerification && _.toLower(data.memoVerificationTest) !== 'false') {
      finishButtons.hide()
      solutionButtons.show()
    } else {
      finishButtons.show()
      solutionButtons.hide()
    }
    clearFeedback()

    _.each(data.solutions, solution => {
      let images = _.map(solution.questionImages, img => ({ src: `/empolis/resource/${img}` }))

      $(`#solution-images-${solution.solutionIndex}`).magnificPopup({
        items: images,
        gallery: { enabled: true },
        type: 'image'
      })

      $(`#solution-references-${solution.solutionIndex}`).click(() => parseReferencesTemplate(solution.references))
      $(`#solution-details-${solution.solutionIndex}`).click(() => displayDetails(solution))
    })
  }

  function displayDetails(solution) {
    let templateName = 'solutionDetails',
      viewTemplate = parsedTemplates[templateName],
      viewTarget = $(`#solutionDetailsTarget`),
      parsedHtml = mustache.render(viewTemplate, { details: solution.questionTestSteps })

    processLinks(parsedHtml, vehicleSession).then(processedHtml =>
      viewTarget.html(processedHtml).ready(() => {
        $('#solutionDetailsModal').modal()
      })
    )
  }

  function parseReferencesTemplate(references) {
    let templateName = 'solutionReferences',
      viewTemplate = parsedTemplates[templateName],
      viewTarget = $(`#solutionReferencesTarget`),
      parsedHtml = mustache.render(viewTemplate, { references: references })

    processLinks(parsedHtml, vehicleSession).then(processedHtml =>
      viewTarget.html(processedHtml).ready(() => $('#solutionReferencesModal').modal())
    )
  }

  function preProcess(data) {
    let testSteps = decodeHtml(data.testSteps),
      memoQuestion = data.memoQuestion
    return Promise.resolve(
      _.merge(
        data,
        {
          // memoQuestion: m,
          hasTable: !!testSteps,
          isDemo: $('body').data('demo')
        },
        scanInfo(data)
      )
    )
  }

  function possibleCausePreProcess(data) {
    let plural = (col, sing, plur) => (_.size(col) === 1 ? sing : plur)

    _.each(data.solutions, (solution, index) => {
      solution.solutionIndex = index
      solution.hasImages = !!_.size(solution.questionImages)
      solution.imagesCaption = `${_.size(solution.questionImages)} ${plural(
        solution.questionImages,
        'Image',
        'Images'
      )}`
      solution.hasReferences = !!_.size(solution.references)
      solution.referenceCaption = `${_.size(solution.references)} ${plural(solution.references, 'Doc', 'Docs')}`
      solution.additionalQuestionDescription = _.get(solution, 'memo.Memo_External', '')
      solution.questionHasAdditionalQuestionDescription = !!solution.additionalQuestionDescription
    })
    return Promise.resolve(data)
  }

  function processHistory(data) {
    let verifiedSolutionText = _.get(data.currentVerifiedSolution, 'phraseText', '')
    $('#finishExistingSolutionModal #existingSolutionText').text(verifiedSolutionText)
    jScrollPane.refresh()
    $('#historyList').empty()
    return Promise.resolve(data)
  }

  let preProcessor = {}

  preProcessor.historyTemplate = data => processHistory(data)

  preProcessor.possibleCausesTemplate = data => Promise.resolve(data)

  preProcessor.questionsTemplate = data => preProcess(data)
  preProcessor.blockedStepTemplate = data => preProcess(data)
  preProcessor.noteOrBlankTemplate = data => preProcess(data)
  preProcessor.infoWarningTemplate = data => preProcess(data)
  preProcessor.infoCautionTemplate = data => preProcess(data)
  preProcessor.solutionTemplate = data => preProcess(data)
  preProcessor.verificationTemplate = data => preProcess(data)
  preProcessor.redoDTCListTemplate = data => preProcess(data)
  preProcessor.possibleCausesTemplateExpert = data => possibleCausePreProcess(data).then(preProcess)
  preProcessor.historyTemplateExpert = data => processHistory(data)
  preProcessor.questionsTemplateExpert = data => preProcess(data)
  preProcessor.toolbarTemplate = data => Promise.resolve(data)
  preProcessor.noteOrBlankTemplateExpert = data => preProcess(data)
  preProcessor.infoCautionTemplateExpert = data => preProcess(data)
  preProcessor.verificationTemplateExpert = data => preProcess(data)
  preProcessor.infoWarningTemplateExpert = data => preProcess(data)
  preProcessor.solutionTemplateExpert = data => preProcess(data)
  preProcessor.diagnosticHeaderTemplate = data => preProcess(data)

  function decorateTestStepLinks() {
    $('.panel-body')
      .find('a[href*=".html"]')
      .each(function () {
        $(this).attr('target', '_blank')
      })

    $('.modal-content.test-steps')
      .find('a[href*=".html"]')
      .each(function () {
        $(this).attr('target', '_blank')
      })
  }

  function displayData(data, callback) {
    let dataQuestion = data.dataQuestion

    attributes = data.attributes
    treeId = data.treeId
    nodeId = data.nodeId
    sessionId = data.sessionId
    isSolution = data.isSolution
    isVerification = data.isVerification
    isSessionFinish = data.isSessionFinish
    currentSolution = data.currentSolution

    $('.innerLayout').hide()
    $('.viewTarget').hide()

    $(`#${data.layoutName}`).show()
    let debugAndReturn = message => data => {
      //console.log(message, data);
      return data
    }

    Promise.each(data.viewNames, viewName => {
      let viewTemplate = parsedTemplates[viewName]
      return viewTemplate
        ? preProcessor[viewName](data)
            .then(data => mustache.render(viewTemplate, data))
            .then(debugAndReturn('rendering '))
            .then(html => processLinks(html, vehicleSession))
            .then(debugAndReturn('processed '))
            .then(
              html =>
                new Promise(resolve => {
                  $(`#${viewName}Target`)
                    .html(html)
                    .ready(() => resolve())
                })
            )
        : null
    })
      .then(() => {
        _.each(data.viewNames, viewName => {
          $(`#${viewName}Target`).show()
        })
        rebind()
        setupGallery('#lightSlider')
        setTimeout(jScrollPane.refresh, 10)
        postProcess(data)
        decorateTestStepLinks()

        if (typeof callback === 'function') callback()
      })
      .catch(err => console.error(err))

    processLinks(data.testSteps, vehicleSession).then(testSteps => {
      $('#testStepsModalTemplateTarget')
        .html(
          mustache.render(testStepsModalTemplate, {
            testSteps: testSteps,
            hasImage: !!data.hasImage,
            memoImages: data.memoImages
          })
        )
        .ready(() => {
          $('#testStepsModal').on('shown.bs.modal', function () {
            setupGallery('#testStepsLightSlider')
          })
        })
    })

    updateHistory(nodeId ? nodeId : 'start')
  }

  function updateHistory(location) {
    if (updateWindowHistory)
      if (window.location.href.includes('#node='))
        history.pushState(location, null, `${window.location.href.split('#node=')[0]}#node=${location}`)
      else history.replaceState(location, null, `${window.location.href.split('#node=')[0]}#node=${location}`)
  }

  function bindHistoryEvents() {
    window.addEventListener('popstate', function (e) {
      loadHistory(e.state)
      updateWindowHistory = false
    })
    window.addEventListener('pushstate', function (e) {
      loadHistory(e.state)
      updateWindowHistory = false
    })
  }

  function loadHistory(location) {
    let historyElement = $(`.history-item[data-node-id='${location}']`),
      lastHistory = $('.history-question').last()
    if (location === 'start') historyElement = $(".history-item[data-start='true']")
    if (location && historyElement.length)
      goToAnswer(historyElement, () => {
        $(`:radio[value="${historyElement.children('.history-answer').last().html()}"]`).prop('checked', true)
      })
    else sendAnswer(lastHistory.siblings('.history-answer').html(), lastHistory.html())
  }

  function scanInfo(data) {
    return {
      hasScanInfo: scantoolSessionId && data.hasScanInfo,
      scanCommand: data.scanCommand,
      scanExpectedAnswer: data.scanExpectedAnswer
    }
  }

  function clearFeedback() {
    $('#feedbackComment').val('')
  }

  function sendAnswer(answer, phrase, callback) {
    var url = `${pathname}/proceed?empolisSessionId=${encodeURI(sessionId)}&scanToolSessionId=${encodeURI(sessionId)}`,
      data = {
        attributes: attributes,
        answer: answer,
        phrase: phrase,
        treeId: treeId,
        nodeId: nodeId,
        language: language
      }
    $.ajax({
      type: 'POST',
      cache: false,
      url: url,
      data: data,
      dataType: 'json',
      success: callback || displayData,
      beforeSend: function () {
        displayAutoAnswerLoading()
      },
      error: function (jqXHR) {
        const errorMessage = _.get(jqXHR, 'responseJSON.error')
        if (errorMessage === 'empolis-exit-node-reached') {
          $('#finishSessionButton').trigger('click')
        }
      }
    })
  }

  function sendFeedback(comment) {
    let data = {
      treeId: treeId,
      nodeId: nodeId,
      comment: comment
    }

    var posting = $.post(`${pathname}/feedback?empolisSessionId=${encodeURI(sessionId)}`, data)

    posting.fail(function (err) {
      errorAlert(`Error submitting feedback: ${_.get(err, 'responseJSON.error.message')}`)
    })
    posting.done(function () {
      successAlert('Feedback successfully submitted.')
    })
  }

  function sendPidLog(value, logType) {
    let data = {
      treeId: treeId,
      nodeId: nodeId,
      value: value,
      logType: logType
    }

    let posting = $.post(`/diagnostic-session/pid-log?empolisSessionId=${encodeURI(sessionId)}`, data)

    posting.fail(function (err) {
      errorAlert(`Error submitting log: ${_.get(err, 'responseJSON.error.message')}`)
    })
    posting.done(function () {
      successAlert('Value successfully submitted.')
    })
  }

  function sendSolution(treeId, nodeId) {
    return new Promise((resolve, reject) => {
      var url = `${pathname}/solve?empolisSessionId=${encodeURI(sessionId)}`,
        data = {
          treeId: treeId,
          nodeId: nodeId
        }
      $.ajax({
        type: 'POST',
        cache: false,
        url: url,
        data: data,
        dataType: 'json',
        success: function (data) {
          resolve(data)
        },
        failure: function (err) {
          reject(err)
        }
      })
    })
  }

  function verify(ecu, dtc, treeId, nodeId, solution) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${pathname}/verify?empolisSessionId=${encodeURI(sessionId)}`, {
          ecu: ecu,
          dtc: dtc,
          treeId: treeId,
          nodeId: nodeId,
          solution: solution
        })
        .then(obj => resolve(obj.data))
        .catch(reject)
    })
  }

  function gotoHistoryEntry(treeId, nodeId, callback) {
    var url = `${pathname}/goto-node?empolisSessionId=${encodeURI(sessionId)}`,
      data = {
        treeId: `${treeId}`,
        nodeId: nodeId
      }
    axios
      .post(url, data)
      .then(obj => {
        displayData(obj.data, callback)
      })
      .catch(handleError)
  }

  function gotoStartHistoryEntry(treeId, nodeId, callback) {
    var url = `${pathname}/goto-start?empolisSessionId=${encodeURI(sessionId)}`,
      data = {
        treeId: `${treeId}`,
        nodeId: nodeId
      }
    axios
      .post(url, data)
      .then(obj => {
        displayData(obj.data, callback)
      })
      .catch(handleError)
  }

  function goToAnswer(item, callback) {
    const treePath = $(item).data('tree-path')
    const nodeId = $(item).data('node-id')
    feedback.track(SESSION_SELECT_HISTORY, {
      [TYPE]: getEventData()[PATH],
      [META]: { treePath, nodeId },
      Procedure: diagnosticSession?.procedureName,
      'Procedure Description': diagnosticSession?.empolisShortDescription,
      Engine: vehicleSession.engineName,
      Locale: vehicleSession.metadata?.locale,
      Region: vehicleSession.metadata?.region,
      'Session Type': vehicleSession.sessionType,
      'User Dealer': vehicleSession.metadata?.orgName,
      'User ID': vehicleSession.metadata?.username,
      'User Level': vehicleSession.metadata?.level,
      'User Name': `${vehicleSession.metadata?.firstName} ${vehicleSession.metadata?.lastName}`
    })
    if ($(item).data('start')) {
      gotoStartHistoryEntry(treePath, nodeId, callback)
    } else {
      gotoHistoryEntry(treePath, nodeId, callback)
    }
  }

  function updateSessionState(vehicleSessionInfo, path, returnPath, solutionNode = false) {
    let data = {
        currentSolution: currentSolution,
        attributes: attributes,
        treeId: treeId,
        nodeId: nodeId,
        language: language,
        vehicleSessionInfo: vehicleSessionInfo,
        solutionNode: solutionNode,
        isSolution: isSolution,
        isVerification: isVerification,
        isSessionFinish: isSessionFinish
      },
      posting = $.post(`${pathname}/${path}?empolisSessionId=${encodeURI(sessionId)}`, data)

    posting.fail(function (err) {
      logger.error('Failed to update session', err)
    })
    posting.done(() => (window.location.href = returnPath || '/'))
  }

  function displayAutoAnswerLoading() {
    let yesRadio = $('input[name=radioAnswer][value=Yes]'),
      noRadio = $('input[name=radioAnswer][value=No]'),
      nextQuestionButton = $('button.nextQuestion'),
      continueButton = $('button.continue')

    noRadio.attr('disabled', 'disabled')
    yesRadio.attr('disabled', 'disabled')
    _.forEach([nextQuestionButton, continueButton], element => element.html('<div class="spinner"></div>'))
  }

  function removeTables(text) {
    return text ? text.replace(/<table>.*<\/table>/, '') : ''
  }

  function hasTable(text) {
    return text ? text.includes('<table>') : false
  }

  function decodeHtml(html) {
    let txt = document.createElement('textarea')
    txt.innerHTML = html || ''
    return txt.value
  }

  function bindIntermittentIncident() {
    $('a.intermittentIncident').on('click', function () {
      window.open(
        location.protocol + '//' + location.host + '/related-reference/GI.pdf#nameddest=E.0000000014608085',
        '_blank'
      )
    })
  }

  function loadForFinished() {
    $('.form-group[data-type="start-another"]').click(function () {
      feedback.track(SESSION_START_ANOTHER, { [TYPE]: getEventData()[PATH] })
    })
  }

  function loadForSummary() {
    $('#summary [data-type="pdf-export"]').click(function () {
      feedback.track(SESSION_EXPORT_SUMMARY, { [TYPE]: getEventData()[PATH] })
    })
  }

  module.exports = {
    connectToyotaScanTool: connectToyotaScanTool,
    load: load,
    loadForFinished: loadForFinished,
    loadForSummary: loadForSummary,
    goToAnswer: goToAnswer,
    lightSlider: () => {
      $('#testStepsLightSlider').lightSlider({
        gallery: false,
        pager: false,
        item: 4,
        loop: false,
        slideMargin: 5,
        thumbItem: 9
      })
    }
  }
})()
