import UIUtils from './ui-utils';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';

window.moment = moment;

const DATEPICKER_OPTIONS = {
  format: screen.width >= 1025 ? 'M d, yyyy' : 'M d',
  templates: {
    leftArrow: '<i class="material-icons">keyboard_arrow_left</i>',
    rightArrow: '<i class="material-icons">keyboard_arrow_right</i>'
  },
  autoclose: true,
  todayHighlight: true,
  toggleActive: true,
  todayBtn: 'linked',
  clearBtn: true
};


function datePickerOptions(obj) {
  return _.merge({}, DATEPICKER_OPTIONS, obj);
}
/* $ selectors*/
const tableSessionList = 'table#settingsHistory',
  inputDatePickerStart = '#datepickerStart',
  inputDatePickerEnd = '#datepickerEnd',
  usernameFilter = '#usernameFilter',
  settingsFilter = '#settingsFilter',
  clearFiltersButton = 'button.clearFilters';

function load() {
  $(function() {
    let filter = {
      beforeDate: null,
      afterDate: null,
      clear: function() {
        this.beforeDate = null;
        this.afterDate = null;
      },
      filterDate: function(value) {
        let target = moment(value, 'MMM DD, YYYY h:mm A');
        let result = true;
        if (this.beforeDate) result = result && target.isAfter(this.beforeDate);
        if (this.afterDate) result = result && target.isBefore(this.afterDate);
        return result;
      }
    };


    let dt = $(tableSessionList).DataTable({
      dom: `<'row'<'col-sm-12' t>><'row'<'col-sm-12' p>>`,
      pageButton: 'bootstrap',
      autoWidth: false,
      ordering: true,
      order: [
        [4, 'asc']
      ],
      'aoColumns': [
        { 'bSortable': true },
        { 'bSortable': true },
        { 'bSortable': true }, {
          'bSortable': true,
          'sType': 'custom-datetime'
        }, {
          'bSortable': true,
          'sType': 'custom-datetime'
        }
      ],
      drawCallback: UIUtils.dataTablesConditionalPaginationCallback
    });

    $.fn.dataTableExt.oSort['custom-datetime-desc'] = function(x, y) {
      let m1 = moment(x, 'lll'),
        m2 = moment(y, 'lll');
      return m2.isBefore(m1) ? -1 : 1;
    };

    $.fn.dataTableExt.oSort['custom-datetime-asc'] = function(x, y) {
      let m1 = moment(x, 'lll'),
        m2 = moment(y, 'lll');
      return m1.isBefore(m2) ? -1 : 1;
    };

    let datePickerStart = $(inputDatePickerStart).datepicker(
      datePickerOptions({
        title: 'Start date',
        container: inputDatePickerStart,
        orientation: 'auto left',
        endDate: new Date()
      })
    );
    let datePickerEnd = $(inputDatePickerEnd).datepicker(
      datePickerOptions({
        title: 'End date',
        container: inputDatePickerEnd
      })
    );


    function search() {
      return (settings, data) => {
        let result = true;
        result = result && filter.filterDate(data[4]);
        return result;
      };
    }

    /* Event binding  */
    $.fn.dataTable.ext.search.push(search());

    let onChangeDate = (prop, dayIncrement) => {
      let inc = dayIncrement ? //
        ((m) => m.add(dayIncrement, 'day')) : //
        _.identity;

      return (e) => {
        if (prop === 'beforeDate') {
          datePickerEnd.datepicker('setStartDate', datePickerStart.datepicker('getDate'));
        }
        if (prop === 'afterDate') {
          datePickerStart.datepicker('setEndDate', datePickerEnd.datepicker('getDate'));
        }

        filter[prop] = e.date ?
          inc(moment(e.date)) : null;
        dt.draw();
      };
    };

    datePickerStart.on('changeDate', onChangeDate('beforeDate'));
    datePickerEnd.on('changeDate', onChangeDate('afterDate', 1));

    let clearFilter = (settingsTable, column) => dt.columns(column).search('').draw();

    $('#usernameFilter, #settingsFilter').on('change', function(e) {
      let column = e.target.id === 'settingsFilter' ? 0 : 3;
      this.value === 'all' ? clearFilter(dt, column) :
        dt.columns(column).search(this.value).draw();
    });

    $(clearFiltersButton).click(() => {
      $(inputDatePickerStart).datepicker('update', '');
      $(inputDatePickerStart).datepicker('setEndDate', new Date());
      $(inputDatePickerEnd).datepicker('update', '');
      $(settingsFilter).selectpicker('val', 'all');
      $(usernameFilter).selectpicker('val', 'all');
      filter.clear();
      dt.columns('').search('').draw();
    });

    dt.order([4, 'desc']).draw();

  });

}

module.exports = {
  load: load
};