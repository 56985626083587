import $ from 'jquery'
import _ from 'lodash'
import { errorAlert, setLoading, clearLoadingButtons, successAlert, clearMessages } from './ui-utils'
import axios from 'axios'
import logger from './logger-service'

const file_selector = '#file-selector',
  file_selected = '#file-selected'

;(function IIFE() {
  let pathname = window.location.pathname.replace(/\/\s*$/, '')

  function load() {
    $('#file-selected').click(function () {
      $(file_selector).click()
    })
    $(file_selector).change(function () {
      var file = $(this).val().replace(/\\/g, '/').replace(/.*\//, '')
      $(file_selected).val(file)
    })

    $('#import-data-button').click(function () {
      clearMessages()
      if (validate()) {
        var data = new FormData()
        data.append('file', document.getElementById('file-selector').files[0])

        setLoading()
        axios
          .post(pathname, data)
          .then(obj => {
            clearLoadingButtons()
            logger.info('Finished Upload', obj.data)
            successAlert(obj.data.success)
          })
          .catch(error => {
            clearLoadingButtons()
            errorAlert(_.get(error, 'response.data.error.error') || error)
          })
      }
    })
  }

  function validate() {
    let file_exist = $(file_selector).val() ? true : errorAlert('No file was uploaded, Please Select a valid file.')
    if (file_exist) {
      let theFile = _.first($(file_selector)[0].files)

      let file_size = theFile.size <= 100000000 ? true : errorAlert('The uploaded file exceeds 100 MB.')
      logger.info('fileSelector', JSON.stringify(theFile))
      return !!file_size
    }
    return !!file_exist
  }

  module.exports = {
    load: load
  }
})()
