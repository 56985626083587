const colors = {
  log: '#999999',
  info: '#66AA66',
  warning: '#FF8C00',
  error: '#FF0000',
  debug: '#B47846',
  feedback: '#4682B4'
}

function logToConsole(message, color) {
  let strings = ['%c']
  let dataObjects = []
  const style = ['color: ' + color, 'display: block', 'text-align: justify', 'font-weight: bold'].join(';')

  // place all the string values in the log message in one array so that we can color them accordingly
  // place all the complex objects (array, obj) in a separate array to be added at the end
  // why all this? to get colors in the console to show up (can only color strings)
  message.forEach(value => {
    typeof value === 'string' ? strings.push(value) : dataObjects.push(value)
  })

  // always show the string values w/appropriate color
  let messageToLog = [strings.join(' ')]

  // use browser's console command to spit it all out
  console.log(messageToLog.toString(), style, ...dataObjects)
}

function log(...args) {
  logToConsole(args, colors.log)
}

function info(...args) {
  logToConsole(args, colors.info)
}

function warn(...args) {
  logToConsole(args, colors.warning)
}

function error(...args) {
  logToConsole(args, colors.error)
}

function debug(...args) {
  logToConsole(args, colors.debug)
}

function feedback(...args) {
  logToConsole(args, colors.feedback)
}

module.exports = {
  log: log,
  info: info,
  warn: warn,
  error: error,
  debug: debug,
  feedback: feedback
}
