import {
  enable,
  fromCodeLabelsToOptions,
  refreshStateCascade,
  refreshSelectpicker,
  prepButtonsForLoading,
  toggleLoading
} from './ui-utils';
import $ from 'jquery';
import _ from 'lodash';
import validateDefaults from './validate-defaults.js';

export default (function IIEF() {
  var inputMake = 'select#inputMake',
    inputId = 'select#inputId',
    inputYear = 'select#inputYear',
    inputModel = 'select#inputModel',
    inputEngineCode = 'select#inputEngineCode',
    inputTransmissionCode = 'select#inputTransmissionCode',
    buttonStart = 'button#start',
    hiddenModelName = 'input#hiddenModelName',
    formVin = '#formVin',
    buttonStartWithVin = 'button#startWithVin';

  return () => {
    prepButtonsForLoading();
    $('select').attr('disabled', 'disabled');
    $(buttonStart).attr('disabled', 'disabled');
    $(inputMake).removeAttr('disabled');

    $(inputMake).change(refreshStateCascade(enableYear, inputYear, inputModel, inputEngineCode, inputTransmissionCode, buttonStart));
    $(inputYear).change(refreshStateCascade(enableModel, inputModel, inputEngineCode, inputTransmissionCode, buttonStart));
    $(inputModel).change(refreshStateCascade(enableEngineCodes, inputEngineCode, inputTransmissionCode, buttonStart));
    $(inputEngineCode).change(refreshStateCascade(enableTransmissionCodes, inputTransmissionCode, buttonStart));
    $(inputTransmissionCode).change(refreshStateCascade(enable(buttonStart), buttonStart));
    $(formVin).validate(validateDefaults());
    $(buttonStartWithVin).click(() => toggleLoading($(formVin).valid()));


    function enableYear(make) {
      fetch(make, 'years').done(fromCodeLabelsToOptions(inputYear));
    }

    function enableModel(year) {
      //retrieve make value to compose fetch request
      var make = $(inputMake).val();

      fetch(make, year, 'models').done(fromCodeLabelsToOptions(inputModel));
    }

    function enableEngineCodes(modelId) {
      //hack that sends server the model description together with modelId
      $(hiddenModelName).val($(inputModel + ' option[value="' + modelId + '"]').text());

      fetch(modelId, 'engineCodes').done(fromCodeLabelsToOptions(inputEngineCode));
    }

    function enableTransmissionCodes() {
      let modelId = $(inputModel).val();
      $(hiddenModelName).val($(inputModel + ' option[value="' + modelId + '"]').text());
      fetch(modelId, 'transmissionCodes').done(fromCodeLabelsToOptions(inputTransmissionCode)).then((codes) => {
        if (!codes.length)
          $(inputTransmissionCode).append('<option val="">No Transmission Codes</option>');
      });
    }

    function fetch() {
      return $.get(['/diagnostic-exploration'].concat(_.toArray(arguments)).join('/'));
    }
  };
})();