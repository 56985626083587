import $ from 'jquery';
import 'bootstrap-datepicker';

const DATEPICKER_OPTIONS = {
  title: 'Title',
  format: 'M d, yyyy',

  templates: {
    leftArrow: '<i class="material-icons">keyboard_arrow_left</i>',
    rightArrow: '<i class="material-icons">keyboard_arrow_right</i>'
  },

  todayHighlight: true
};

$(() => {
  $('#date-1').datepicker(DATEPICKER_OPTIONS);
  $('#date-2').datepicker(DATEPICKER_OPTIONS);
  $('#date-3').datepicker(DATEPICKER_OPTIONS);
  $('#date-4').datepicker(DATEPICKER_OPTIONS);
});
