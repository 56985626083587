import logger from './logger-service'
import mixpanel from 'mixpanel-browser'
import platform from 'platform'
import * as _ from 'lodash'

// TODO: create a service to return token according to the environment (e.g., dev | test | pilot | production)
mixpanel.init('db22adf1d1fd4b52522b593fdfaa34c5')

// TODO: call mixpanel people api to record user information (e.g., name, language, theme, etc)

function track(eventName, data) {
  // TODO: bring in rules to exclude users when appropriate (e.g., the user for automated test runs)
  const analyticDataStr = localStorage.getItem('analyticData') || '{}'
  const analyticData = JSON.parse(analyticDataStr)

  let augmentedData = {
    'Serial Number': analyticData.VIN,
    Product: analyticData.YME,
    'OS Version': platform.os.version,
    'OS Architecture': platform.os.architecture,
    'Display Device': platform.product ? platform.product : 'Computer',
    'Platform Description': platform.description,
    'Platform Manufacturer': platform.manufacturer
  }

  _.extend(augmentedData, data)

  mixpanel.track(eventName, augmentedData)

  logger.feedback('Mixpanel Feedback: ', eventName, augmentedData)
}

module.exports = {
  track: track
}
