import $ from 'jquery'
import { createStringId } from './ui-utils'
import { processLinks } from './link-processor'
import { inquiryConfigNames } from './select-ecu-configuration-modal'
import logger from './logger-service'
import feedback from './feedback-service'
import FeedbackConstants from './feedback/feedbackConstants'
import { getEventData } from './feedback/feedbackStorage'

function showImage(tabIdName, anchor) {
  let tabId = `#${tabIdName}`
  let path = $('img', $(anchor)).attr('src')
  $(tabId).css('max-width', $('.fullscreen-modal-box').width() - 30)
  let html = `<div class="image-panning"><img src="${path}"/></div>`

  $('a.thumbnail.thumb-previous-selected').removeClass('thumb-previous-selected')
  $('a.thumbnail.thumb-selected').removeClass('thumb-selected').addClass('thumb-previous-selected')
  $(anchor).addClass('thumb-selected')

  $(html)
    .appendTo($(tabId).empty())
    .ready(function () {
      setTimeout(() => {
        $('.image-panning', $(tabId))
          .panzoom({
            $zoomIn: $(`${tabId}-zoom-in`),
            $zoomOut: $(`${tabId}-zoom-out`),
            increment: 0.05,
            // Pan only when the scale is greater than minScale
            panOnlyWhenZoomed: true
          })
          .panzoom('resetZoom')
      }, 200)
    })
}

function showRelatedContentFromEmpolis(button) {
  window.event.preventDefault()
  const {
    events: { SESSION_OPEN_RELATED_CONTENT },
    fields: { TYPE, PATH }
  } = FeedbackConstants
  feedback.track(SESSION_OPEN_RELATED_CONTENT, { [TYPE]: getEventData()[PATH] })

  let year = $(button).attr('data-year'),
    make = $(button).attr('data-make'),
    model = $(button).attr('data-model'),
    treeId = $(button).attr('data-tree-id') || '',
    vehicleSessionId = $(button).attr('data-vehicle-session-id'),
    dialogId = `#details-modal-${vehicleSessionId}`

  logger.debug('Show related content', button)
  if (!$('#dialogs', $('body')).length) {
    $('<div id="dialogs"></div>').appendTo($('body'))
  }

  $.get(`/related-content/dialog-empolis/${vehicleSessionId}?treeId=${treeId}`, data => {
    $('#dialogs', $('body'))
      .empty()
      .append($(data))
      .ready(() => {
        let dialog = $(dialogId)

        function handleTabs(e) {
          let tabId = $(e.target).attr('href')

          // Dropdown menu for mobile
          let menuItem = $('.dropdown-menu li').find(`a[href='${tabId}']`)
          $('.dropdown-toggle #dropdown-text').text(menuItem.text())

          setupGallery($(`${tabId}-lightSlider`, dialog))
          lazyLoadRelatedContent($(tabId, dialog), { year: year, make: make, model: model })
        }

        $('.dropdown-menu li a').click(function (e) {
          e.preventDefault()
          const href = $(e.target).attr('href')
          $(`ul#modal-tab.nav.nav-pills a[href='${href}']`, dialog).tab('show')
        })

        $('ul#modal-tab.nav.nav-pills a', dialog).click(function (e) {
          e.preventDefault()
          $(this).tab('show')
        })

        $('ul#modal-tab.nav.nav-pills a', dialog).on('shown.bs.tab', handleTabs)
        $('ul#modal-tab.nav.nav-pills a:first', dialog).tab('show')

        dialog.show()
      }, 'html')
  })

  function setupGallery(target) {
    if (!target.data('loaded')) {
      target.lightSlider({
        gallery: false,
        pager: false,
        item: 8,
        loop: false,
        slideMargin: 20,
        thumbItem: 12
        // prevHtml: `<a class="lSPrev"><span class="material-icons bigArrow">keyboard_arrow_left</span></a>`,
        // nextHtml: `<a class="lSNext"><span class="material-icons bigArrow">keyboard_arrow_right</span></a>`
      })
      target.data('loaded', true)
      target.fadeIn()
      $('a:first', target).click()
    }
  }

  function lazyLoadRelatedContent(target, vehicle) {
    const pdfIframe = $('#pdfIframe')
    if (pdfIframe) {
      const pdfSrc = pdfIframe.data('pdf')
      const pdfUrl = `/vendor/pdfjs/web/viewer.html?file=${encodeURIComponent(
        new URL(pdfSrc, window.location.href).href
      )}`
      if (!pdfIframe.attr('src')) {
        pdfIframe.attr('src', pdfUrl)
      }
    }
    if (!target.data('loaded')) {
      target.find('.content').each(function () {
        let src = $(this).data('src')
        if (!src) return
        $.get(src, data => {
          target.find('.loading-image').fadeOut(2000).remove()
          processLinks(data, vehicle).then(html => {
            $(this).html(html)
          })
        })
      })
      target.data('loaded', true)
    }
  }
}

function showRelatedContent(button) {
  window.event.preventDefault()
  let dtc = $(button).attr('data-dtc'),
    ecu = $(button).attr('data-ecu'),
    year = $(button).attr('data-year'),
    make = $(button).attr('data-make'),
    model = $(button).attr('data-model'),
    encodedEcu = encodeURIComponent(ecu),
    vehicleSessionId = $(button).attr('data-vehicle-session-id'),
    dialogId = createStringId('-', '#details-modal', ecu.replace(/[^a-zA-Z0-9]/g, ''), dtc)
  /****
   * REMINDER: KEEP this dialogId in SYNC WITH controller code function getModalId
   */

  logger.log('Inquiring config names ')
  inquiryConfigNames(vehicleSessionId, encodedEcu, dtc, selectedConfigName => {
    logger.debug('Show related content', button)
    if (!$('#dialogs', $('body')).length) {
      $('<div id="dialogs"></div>').appendTo($('body'))
    }
    let configurationQuery = (selectedConfigName && `?configurationName=${selectedConfigName}`) || ''
    $.get(
      `/related-content/dialog/${vehicleSessionId}/${encodedEcu}/${dtc}${configurationQuery}`,
      data => {
        $('#dialogs', $('body'))
          .empty()
          .append($(data))
          .ready(() => {
            let dialog = $(dialogId)
            $('ul#modal-tab.nav.nav-pills a', dialog).click(function (e) {
              e.preventDefault()
              $(this).tab('show')
            })

            $('ul#modal-tab.nav.nav-pills a', dialog).on('shown.bs.tab', function (e) {
              let tabId = $(e.target).attr('href')
              $(`${tabId}-footer`).addClass('active')
              $(`${$(e.relatedTarget).attr('href')}-footer`).removeClass('active')
              setupGallery($(`${tabId}-lightSlider`, dialog))
              lazyLoadRelatedContent($(tabId, dialog), { year: year, make: make, model: model })
            })

            $('ul#modal-tab.nav.nav-pills a:first', dialog).tab('show')

            dialog.show()
          })
      },
      'html'
    )
  })

  function setupGallery(target) {
    if (!target.data('loaded')) {
      target.lightSlider({
        gallery: false,
        pager: false,
        item: 8,
        loop: false,
        slideMargin: 20,
        thumbItem: 12
        // prevHtml: `<a class="lSPrev"><span class="material-icons bigArrow">keyboard_arrow_left</span></a>`,
        // nextHtml: `<a class="lSNext"><span class="material-icons bigArrow">keyboard_arrow_right</span></a>`
      })
      target.data('loaded', true)
      target.fadeIn()
      $('a:first', target).click()
    }
  }

  function lazyLoadRelatedContent(target, vehicle) {
    if (!target.data('loaded')) {
      target.find('.content').each(function () {
        let src = $(this).data('src')
        if (!src) return
        $.get(src, data => {
          target.find('.loading-image').fadeOut(2000).remove()
          processLinks(data, vehicle).then(html => {
            $(this).html(html)
          })
        })
      })
      target.data('loaded', true)
    }
  }
}

module.exports = {
  showImage: showImage,
  showRelatedContent: showRelatedContent,
  showRelatedContentFromEmpolis
}
