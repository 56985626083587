import $ from 'jquery'
import _ from 'lodash'
import validateDefaults from './validate-defaults'
import { errorAlert, setLoading, clearLoadingButtons, successAlert, clearMessages } from './ui-utils'
import axios from 'axios'
import logger from './logger-service'

const fileSelector = '#file-selector',
  fileSelected = '#file-selected',
  formUpload = '#formUpload'

let pathname = window.location.pathname.replace(/\/\s*$/, '')

function load() {
  $(formUpload).validate()
  $('#file-selected').click(function () {
    $(fileSelector).click()
  })
  $(fileSelector).change(function () {
    var file = $(this).val().replace(/\\/g, '/').replace(/.*\//, '')
    $(fileSelected).val(file)
  })

  $('#import-data-button').click(function () {
    logger.debug('Clicked Import Data')
    clearMessages()
    if ($(formUpload).valid() && validate()) {
      var data = new FormData()
      data.append('file', document.getElementById('file-selector').files[0])
      data.set('model', $('#model').val())
      data.set('year', $('#year').val())

      setLoading()

      axios
        .post(pathname, data)
        .then(obj => {
          clearLoadingButtons()
          window.location = `/admin/jobs`
        })
        .catch(error => {
          clearLoadingButtons()
          errorAlert(_.get(error, 'response.data.error.error') || error)
        })
    }
  })
}

function validate() {
  let file_exist = $(fileSelector).val() ? true : errorAlert('No file was uploaded, Please Select a valid file.')
  if (file_exist) {
    let theFile = _.first($(fileSelector)[0].files)

    let file_size = theFile.size <= 100000000 ? true : errorAlert('The uploaded file exceeds 100 MB.')
    logger.info('fileSelector', JSON.stringify(theFile))
    return !!file_size
  }
  return !!file_exist
}

module.exports = {
  load: load
}
