import $ from 'jquery'
import _ from 'lodash'
import validateDefaults from './validate-defaults'
import { prepButtonsForLoading, clearLoadingButtons, setLoading, errorAlert } from './ui-utils'
import logger from './logger-service'
import feedback from './feedback-service'
import FeedbackConstants from './feedback/feedbackConstants'
import { storeEventData } from './feedback/feedbackStorage'
const formVin = '#form-vin'

function searchVin(success) {
  return () => {
    if ($(formVin).valid()) {
      setLoading()
      var vin = $('#inputVin').val().replace(/\s+/g, '')
      $.get(`/search-vin?vin=${vin}`)
        .done(data => {
          window.location = success(data)
        })
        .fail(err => {
          clearLoadingButtons()
          if (err.status === 500) {
            window.location = `/manual-mode?vin=${vin}`
          } else {
            $('#server-error .alert-content').html(err.responseJSON.error.message)
            $('#server-error').fadeIn()
          }
        })
    } else {
      logger.log('Clear loading buttons')
      clearLoadingButtons()
    }
  }
}

function searchSerial(success) {
  return () => {
    setLoading()
    var sn = $('#inputVin').val()
    $.get(`/search-serial?sn=${sn}`)
      .done(data => {
        window.location = success(data)
      })
      .fail(err => {
        clearLoadingButtons()
        $('#server-error .alert-content').html(err.responseJSON.error.message)
        $('#server-error').fadeIn()
      })
  }
}

function loadVinEntry() {
  $(formVin).submit(() => false)
  $(formVin).validate(validateDefaults())
}

function loadFileUpload() {
  //disable dragover for the whole page
  $(document).bind('drop dragover', e => {
    e.preventDefault()
  })

  $('#dragndrop-zone .clickable').click(e => {
    e.stopPropagation()
    $('#uploadFile').click()
  })

  $('#uploadFile')
    .fileupload({
      url: '/import-vehicle-data-upload',
      dataType: 'json',
      dropZone: $('#dragndrop-zone'),
      paramName: 'file',
      done: (e, data) => {
        window.location = data.result.next
      }
    })
    .bind('fileuploaddragover', () => {
      $('#dragndrop-zone .dragndrop-box-content .centered-content').hide()
      $('#uploadindFile .progress-bar').width('0')
      $('#draggingOverFile').show()

      $('#dragndrop-zone') //
        .addClass('dragndrop-box-over')
        .removeClass('dragndrop-box-error')
        .removeClass('dragndrop-box-plain')
    })
    .bind('fileuploadstart', (e, data) => {
      $('#dragndrop-zone .dragndrop-box-content .centered-content').hide()
      $('#uploadindFile').show()
    })
    .bind('fileuploaddrop', (e, data) => {
      $('#dragndrop-zone .dragndrop-box-content .centered-content').hide()
      $('#uploadingFileName').html(_.first(data.files).name)
      $('#uploadindFile').show()
    })
    .bind('fileuploadprogress', (e, data) => {
      let progress = parseInt((data.loaded / data.total) * 100, 10)
      $('#uploadindFile .progress-bar').width(`${progress}%`)
      $('#uploadingProgressStatus').html(`Uploading (${Math.trunc(data.total / 1000)}kb) - ${progress}%`)
    })
    .bind('fileuploadfail', (e, data) => {
      $('#dragndrop-zone .dragndrop-box-content .centered-content').hide()
      $('#errorUploadingFile').show()
      $('#dragndrop-zone') //
        .addClass('dragndrop-box-error')
        .removeClass('dragndrop-box-plain')
        .removeClass('dragndrop-box-over')

      logger.error(_.get(data, 'jqXHR.responseJSON.error.message'))
      $('#errorUploadingFile h4').html(_.get(data, 'jqXHR.responseJSON.error.message', data.errorThrown))
    })
}

function loadPasteUpload() {
  $('button.paste-file').click(() => {
    $('#modalError').hide()
    $('#paste-file-dialog').modal()
  })
  $('#form-paste').submit(() => false)
  let validator = $('#form-paste').validate(validateDefaults())

  $('#paste-file-dialog').on('hidden.bs.modal', function () {
    validator.resetForm()
    let formPaste = $('#form-paste .input-validate-wrapper')
    formPaste.find('i').detach()
    formPaste.removeClass('input-validate-wrapper')
    $('div#textarea-paste-json-error.error-input.error-text').detach()
  })

  $('button#button-submit-paste').click(() => {
    if (!$('#form-paste').valid()) {
      $('#textarea-paste-json').focus()
      return
    }

    $.post('/import-vehicle-data-upload', { data: $('#textarea-paste-json').val() }) //
      .done(data => (window.location = data.next)) //
      .fail(data => {
        logger.error(data)
        $('#modalError .alert-content').html(data.responseJSON.error.message)
        $('#modalError').fadeIn()
      })
  })
}

function updateIntervalText(text) {
  if (text.length > 16) {
    return 'Reading DTCs.'
  }
  return text + '.'
}

function addProgressIndicator() {
  let indicatorText = 'Reading DTCs.'
  let readingDtcsIndicator = '<h4 id="read-dtc-indicator">' + indicatorText + '</h4>'
  $('#toyota-scan-vin').after(readingDtcsIndicator)
  return setInterval(() => {
    indicatorText = updateIntervalText(indicatorText)
    $('#read-dtc-indicator').text(indicatorText)
  }, 750)
}

const SUPPORTED_ECUS = [105, 121, 237, 239, 197, 220, 234]

function readDtcs(ecus, vin) {
  // const toyota = require('toyota-scan-tool-lib').default;

  // TODO: show a loader while we read these?
  let interval = addProgressIndicator()

  let promises = []
  toyota.options.timeout = 240 * 1000
  ecus.forEach(ecu => {
    if (!SUPPORTED_ECUS.includes(ecu.Ecuid)) {
      return
    }
    promises.push(toyota.checkEcuForDtc(ecu.Ecuid))
  })

  Promise.all(promises)
    .then(responseArr => {
      clearInterval(interval)
      $('#read-dtc-indicator').remove()
      $('#button-dtc').prop('disabled', false)
      logger.info('DTC info', responseArr)

      let startButton =
        '<div class="small-top-margin"><button id="button-start-session" class="btn btn-second btn-block">START SESSION</button></div>'
      $('#toyota-scan-vin').after(startButton)
      $('#button-start-session').click(e => {
        logger.log('User started session')
        // TODO: Remove hard coded DTC in body
        let body = {
          vehicleOptions: getSelectedOptions(),
          ecuDetails: ecus,
          dtcs: []
        }

        responseArr.forEach(read => {
          if (!read.dtcdetails) {
            return
          }
          read.dtcdetails.forEach(dtc => {
            if (
              !body.dtcs.find(function (elem) {
                return elem.dtc === dtc.dtcCode
              })
            ) {
              body.dtcs.push({
                ecu: read.ecuid,
                dtc: dtc.dtcCode,
                state: 'Active'
              })
            }
          })
        })
        $.post('/create-vehicle-session/' + vin, body)
          // $.post('/create-vehicle-session/2T3RFREV1JW785138', body) //
          .done(data => {
            window.location = data.next
          })
          .fail(data => {
            logger.error(data)
            $('#modalError .alert-content').html(data.responseJSON.error.message)
            $('#modalError').fadeIn()
          })
      })
    })
    .catch(error => {
      logger.error('Failed reading ECUs', error)
    })
}

function finishConnection(ecuDetails, vin) {
  let vinHtml = '<h4 id="toyota-scan-vin">Connected to VIN: ' + vin + '</h4>'
  $('#toyota-connect-form').after(vinHtml)
  readDtcs(ecuDetails, vin)
}

function sendOption(optionKey, optionId) {
  // const toyota = require('toyota-scan-tool-lib').default;
  toyota
    .getOptions(optionKey, optionId)
    .then(response => {
      if (response.options) {
        appendSelectField(response.options, response.vehicleoptionid)
      } else {
        logger.log('Done sending options', response)
        finishConnection(response.EcuDetails, response.vehicledata.VIN)
      }
    })
    .catch(error => {
      logger.error('Select option error', error)
    })
}

function removeConnectedElements() {
  if ($('#toyota-scan-vin')) {
    $('#toyota-scan-vin').remove()
  }
  if ($('#button-start-session')) {
    $('#button-start-session').parent().remove()
  }
  if ($('#read-dtc-indicator')) {
    $('#read-dtc-indicator').remove()
  }
}

function getSelectedOptions() {
  let options = []
  $('#toyota-connect-form > div > select').each(function () {
    let optionKey = $(this).attr('optionkey')
    options.push({
      optionKey: optionKey,
      optionId: $('#toyota-select-' + optionKey + ' option:selected').attr('optionid')
    })
  })
  return options
}

function appendSelectField(options, optionKey) {
  if ($('#toyota-select-' + optionKey)) {
    let existing = $('#toyota-select-' + optionKey).parent()
    existing.nextAll().remove()
    existing.remove()
  }
  let fieldHtml =
    '<div><select optionkey="' +
    optionKey +
    '" id="toyota-select-' +
    optionKey +
    '" required="required" class="form-control"></select></div>'
  $('#toyota-connect-form').append(fieldHtml)
  let firstDisabledOption = '<option value="" disabled selected>Select a vehicle option</option>'
  let selectField = $('#toyota-select-' + optionKey)
  selectField.append(firstDisabledOption)
  selectField.change(e => {
    let optionId = $('#toyota-select-' + optionKey + ' option:selected').attr('optionid')
    removeConnectedElements()
    sendOption(optionKey, optionId)
  })
  $.each(options, function (index, value) {
    $('#toyota-select-' + optionKey).append(
      $('<option/>', {
        value: value.optionname,
        text: value.optionname,
        optionid: value.id
      })
    )
  })
}

function connectToyotaScanTool() {
  return () => {
    // const toyota = require('toyota-scan-tool-lib').default;
    $('#toyota-connect-form').empty()
    $('#button-dtc').prop('disabled', true)

    removeConnectedElements()
    toyota
      .connectToDenso()
      .then(response => {
        logger.info('Connection data', response)
        if (response.options) {
          appendSelectField(response.options, response.vehicleoptionid)
        } else {
          logger.log('Done connecting do something else')
          finishConnection(response.EcuDetails, response.vehicledata.VIN)
        }
      })
      .catch(error => {
        errorAlert(
          'Failed to establish connection to GTS. This might be due to a certificate issue. Navigate to <a href="https://localhost:9000" target="_blank">https://localhost:9000</a> and accept the certificate.'
        )
        $('#button-dtc').prop('disabled', false)
        logger.error(error)
      })
  }
}

function addTracking() {
  const {
    fields: { SERIAL, PATH, DTC, SYMPTOM },
    events
  } = FeedbackConstants
  let handleClick = function (Path) {
    const serial = $('#inputVin').val()
    const data = { [SERIAL]: serial, [PATH]: Path }

    feedback.track(events.SELECT_EQUIPMENT, data)
    storeEventData(data, true)
  }

  $('#button-dtc').click(() => handleClick(DTC))
  $('#button-symptom').click(() => handleClick(SYMPTOM))
}

module.exports = {
  searchSerial: searchSerial(data => `/create-vehicle-session-serial/${data.vin}`),
  searchSerialSymptoms: searchSerial(data => `/create-vehicle-serial-symptom-session/${data.vin}`),
  searchVinAndAddSymptoms: searchVin(data => `/create-vehicle-symptom-session/${data.vin}`),
  searchVinAndAddCodes: searchVin(data => `/create-vehicle-session/${data.vin}`),
  searchVinAndStartSession: searchVin(data => `/create-vehicle-session/${data.vin}?noComm=true`),
  connectToyotaScanTool: connectToyotaScanTool(),
  load: () => {
    prepButtonsForLoading()
    loadVinEntry()
    loadFileUpload()
    loadPasteUpload()
    addTracking()
  }
}
