import {
  disableAndClean,
  enable,
  fromCodeLabelsToOptions,
  refreshStateCascade,
  prepButtonsForLoading,
  refreshSelectpicker,
  contextPath
} from './ui-utils';
import $ from 'jquery';
import _ from 'lodash';
import axios from 'axios';

const deleteSessionButton = '.delete-session',
  refreshDtcButton = '.refresh-dtcs';

(function IIFE() {

  function load() {
    bind();
    $('.refresh-dtcs').click();
  }



  function bindButtons() {
    $(deleteSessionButton).click(function() {
      let id = $(this).data('id');
      $(this).closest('tr').remove();

      axios.delete(contextPath(`/connected-vehicles/${id}`))
        .then((res) => {});

    });

    $(refreshDtcButton).click(function() {
      let sessionId = $(this).data('id'),
        tableCellWithLength = $(`.length_${sessionId}`),
        url = `/scan-inquiry/${sessionId}/dtc`;

      tableCellWithLength.html('');
      $('<div class="spinner"/>').appendTo(tableCellWithLength);
      axios.get(url).then((res) => {
        tableCellWithLength.html(_.size(res.data.dtcs));
      }).catch((error) => {
        $(this).closest('tr').find(deleteSessionButton).click();
      });


    });
  }

  function bind() {
    bindButtons();
  }

  module.exports = {
    load: load
  };

})();