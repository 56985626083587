import $ from 'jquery';
import _ from 'lodash';

const settingsForm = '#settingsForm',
  frequencyRelevancyRanking = '#frequencyRelevancyRanking',
  dtcRelevancyRanking = '#dtcRelevancyRanking',
  frequencyRelevancyRankingInputs = ['#inputRankingFactor', '#inputOverallFrequencyFactor', '#inputFrequencyFactor', '#inputDistanceFactor', '#inputSmeFactor'],
  dtcRankingFactors = '#dtcRankingFactors';

(function IIFE() {

  function load() {
    bind();
  }

  function bindFrequencyRelevancyRanking() {
    frequencyRelevancyRankingInputs.forEach(function(input) {
      $(input).attr('disabled', !$(frequencyRelevancyRanking).attr('checked'));
      $(frequencyRelevancyRanking).click(function() {
        $(input).attr('disabled', !this.checked);
      });

      $(input).on('input', function() {
        this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      });
    });
  }

  function bindDtcPriorityRelevancyRanking() {
    $(dtcRelevancyRanking).attr('checked') ? $(dtcRankingFactors).show() : $(dtcRankingFactors).hide();
    $(dtcRelevancyRanking).click(() => $(dtcRankingFactors).toggle());
  }

  function bindSubmit() {
    $(settingsForm).submit(function() {
      frequencyRelevancyRankingInputs.forEach(function(input) {
        $(input).prop('disabled', false);
      });
    });
  }

  function bind() {
    bindFrequencyRelevancyRanking();
    bindDtcPriorityRelevancyRanking();
    bindSubmit();
  }

  module.exports = {
    load: load
  };

})();