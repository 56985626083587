import { contextPath } from './ui-utils'
import $ from 'jquery'
import _ from 'lodash'
import axios from 'axios'
import { errorAlert, setLoading, clearLoadingButtons } from './ui-utils'
import mustache from 'mustache'
import logger from './logger-service'
import feedback from './feedback-service'
import FeedbackConstants from './feedback/feedbackConstants'
import { getEventData, storeEventData } from './feedback/feedbackStorage'
;(function IIFE() {
  let symptomsTemplate = null

  function getSessionId() {
    return $(location).attr('pathname').split('/').pop()
  }

  function removeHits(s) {
    return s ? s.replace(/<hit>/gi, '').replace(/<\/hit>/gi, '') : ''
  }

  function replaceHits(s) {
    return s ? s.replace(/<hit>/gi, '<em>[').replace(/<\/hit>/gi, ']</em>') : ''
  }

  let applyTemplate = sessionId => trees => {
    _.forEach(trees, tree => {
      tree.labelHighlighted = tree.Memo_friendlyTreeTitle || replaceHits(tree.Label)
      tree.labelCleared = tree.Memo_friendlyTreeTitle || removeHits(tree.Label)
      tree.commentsHighlighted = tree.Memo_shortDescription_txt || replaceHits(tree.Comment_txt)
      tree.commentsCleared = tree.Memo_shortDescription_txt || tree.Comment_txt
      tree.scorePercentage = (tree._score * 100).toFixed(0)
    })

    $('#symptomsTemplateTarget').html(
      mustache.render(symptomsTemplate, {
        trees: trees,
        sessionId: sessionId,
        hasTrees: !!_.size(trees),
        resultsLabel: _.size(trees) === 1 ? '1 Result Found' : `${_.size(trees)} Results Found`
      })
    )

    bind()
  }

  function submitSerialSearch(symptom) {
    feedback.track('Search for Symptom', { 'Symptom Search Term': symptom })
    let sessionId = getSessionId()
    if (symptom) {
      setLoading()
      disableFormInputs()
      axios
        .get(contextPath(`/diagnostic-session/${sessionId}/serial-symptom-trees`), { params: { symptom: symptom } })
        .then(res => applyTemplate(sessionId)(res.data))
        .then(() => enableFormInputs())
        .then(() => clearLoadingButtons())
        .catch(err => {
          applyTemplate(sessionId)([])
          enableFormInputs()
          clearLoadingButtons()
          errorAlert(err.message)
          logger.error(err)
        })
    }
  }

  function submitSearch(symptom) {
    feedback.track('Search for Symptom', { 'Symptom Search Term': symptom })
    let sessionId = getSessionId()
    if (symptom) {
      setLoading()
      disableFormInputs()
      axios
        .get(contextPath(`/diagnostic-session/${sessionId}/symptom-trees`), { params: { symptom: symptom } })
        .then(res => applyTemplate(sessionId)(res.data))
        .then(() => enableFormInputs())
        .then(() => clearLoadingButtons())
        .catch(err => {
          applyTemplate(sessionId)([])
          enableFormInputs()
          clearLoadingButtons()
          if (err.response.status === 500) {
            errorAlert(err.message)
            logger.error(err)
          }
        })
    }
  }

  function disableFormInputs() {
    $('#input-symptom').prop('disabled', true)
    $('.btn-search').prop('disabled', true)
  }

  function enableFormInputs() {
    $('#input-symptom').prop('disabled', false).focus()
    $('.btn-search').prop('disabled', false)
  }

  function startSession(treeId) {
    setLoading()
    window.location.href = contextPath(`/diagnostic-session/${getSessionId()}/trees/${treeId}/by-symptom/start`)
  }

  function fireTrackEvent() {
    const { events, fields } = FeedbackConstants

    let row = $(this).closest('tr')
    const title = row.children('td').eq(0).attr('title')
    const description = row.children('td').eq(1).text()
    const rank = (+row.find('.meter span').attr('title')?.split('%')?.[0]).toFixed(2)

    let eventData = getEventData()
    const term = eventData[fields.TERM]
    const data = {
      [fields.TERM]: term,
      [fields.PROCEDURE]: title,
      [fields.DESCRIPTION]: description,
      [fields.RANK]: rank
    }
    feedback.track(events.SYMPTOM_START_DIAGNOSTIC, data)
    return { ...eventData, ...data }
  }

  function bind() {
    $('.btn-start-session').click(function () {
      const data = fireTrackEvent.call(this)
      storeEventData(data)
      startSession($(this).data('tree-id'))
    })
  }

  function load() {
    symptomsTemplate = $('#symptomsTemplate').html()
    mustache.parse(symptomsTemplate)

    const handleClick = function (cb) {
      const { events, fields } = FeedbackConstants
      const symptom = $('#input-symptom').val()
      let data = { Term: symptom }

      feedback.track(events.SYMPTOM_SEARCH, { [fields.TERM]: symptom })
      cb(symptom)
      storeEventData(data)
    }

    $('.btn-search').click(() => handleClick(submitSearch))
    $('.btn-serial-search').click(() => handleClick(submitSerialSearch))

    $('#form-search').on('submit', function (e) {
      e.preventDefault()
    })
  }

  module.exports = {
    load: load
  }
})()
