import $ from 'jquery';
const sessions = '.sessions';

let load = (isConnected) => $(() => overrideNavForConnectedVehicles(isConnected));

let overrideNavForConnectedVehicles = (isConnected) => {
  if (isConnected) {
    $('a:contains("Manual Session")').removeClass('active');
    $('a:contains("Connected Vehicles")').removeClass('undefined').addClass('active');
  }
};

module.exports = {
  load: load
};