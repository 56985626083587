import UIUtils from './ui-utils'
import moment from 'moment'
import _ from 'lodash'
import $ from 'jquery'
import logger from './logger-service'
import feedback from './feedback-service'
import FeedbackConstants from './feedback/feedbackConstants'
import { getEventData } from './feedback/feedbackStorage'

/* index of columns */
const VIN_COLUMN = 0,
  USER_COLUMN = 1,
  STATUS_COLUMN = 2,
  START_COLUMN = 3,
  MODIFIED_COLUMN = 4

/* Statuts to Session Status Map */
const SESSION_STATUS_MAP = {
  all: [],
  in_progress: ['In Progress', 'Paused'],
  closed: ['Closed New Solution', 'Closed Existing Solution'],
  canceled: ['Canceled']
}

window.moment = moment

const DATEPICKER_OPTIONS = {
  // 1024 - max-width of iPad
  format: screen.width >= 1025 ? 'M d, yyyy' : 'M d',
  templates: {
    leftArrow: '<i class="material-icons">keyboard_arrow_left</i>',
    rightArrow: '<i class="material-icons">keyboard_arrow_right</i>'
  },
  autoclose: true,
  todayHighlight: true,
  toggleActive: true,
  todayBtn: 'linked',
  clearBtn: true
}

function datePickerOptions(obj) {
  return _.merge({}, DATEPICKER_OPTIONS, obj)
}
/* $ selectors*/
const tableSessionList = 'table#sessionList',
  inputDatePickerStart = '#datepickerStart',
  inputDatePickerEnd = '#datepickerEnd',
  inputVin = '#vinFilter',
  inputUser = '#userFilter',
  selectStatus = '#session-state-filter',
  clearFiltersButton = 'button.clearFilters'

function load() {
  feedback.track('Enter Vehicle Sessions')
  $(function () {
    /* Component initialization */
    let filter = {
      vin: '',
      user: '',
      statuses: [],
      beforeDate: null,
      afterDate: null,
      clear: function () {
        this.vin = ''
        this.user = ''
        this.statuses = []
        this.beforeDate = null
        this.afterDate = null
      },
      filterVin: function (value) {
        return !this.vin ? true : value.indexOf(this.vin) > -1
      },
      filterUser: function (value) {
        return !this.user ? true : value.indexOf(this.user) > -1
      },
      filterStatuses: function (value) {
        return _.isEmpty(this.statuses)
          ? true
          : !_(this.statuses)
              .filter(item => value.startsWith(item))
              .isEmpty()
      },
      filterDate: function (value) {
        let target = moment(value, 'MMM DD, YYYY h:mm A')
        let result = true
        if (this.beforeDate) result = result && target.isAfter(this.beforeDate)
        if (this.afterDate) result = result && target.isBefore(this.afterDate)
        return result
      }
    }

    let dt = $(tableSessionList).DataTable({
      dom: `<'row'<'col-sm-12' t>><'row'<'col-sm-12' p>>`,
      pageButton: 'bootstrap',
      autoWidth: false,
      ordering: true,
      order: [[4, 'asc']],
      aoColumns: [
        { bSortable: true },
        { bSortable: true },
        { bSortable: true },
        {
          bSortable: true,
          sType: 'custom-datetime'
        },
        {
          bSortable: true,
          sType: 'custom-datetime'
        },
        { bSortable: false }
      ],
      drawCallback: UIUtils.dataTablesConditionalPaginationCallback
    })

    $.fn.dataTableExt.oSort['custom-datetime-desc'] = function (x, y) {
      let m1 = moment(x, 'lll'),
        m2 = moment(y, 'lll')
      return m2.isBefore(m1) ? -1 : 1
    }

    $.fn.dataTableExt.oSort['custom-datetime-asc'] = function (x, y) {
      let m1 = moment(x, 'lll'),
        m2 = moment(y, 'lll')
      return m1.isBefore(m2) ? -1 : 1
    }

    let datePickerStart = $(inputDatePickerStart).datepicker(
      datePickerOptions({
        title: 'Start date',
        container: inputDatePickerStart,
        orientation: 'auto left',
        endDate: new Date()
      })
    )
    let datePickerEnd = $(inputDatePickerEnd).datepicker(
      datePickerOptions({
        title: 'End date',
        container: inputDatePickerEnd
      })
    )

    function search() {
      return (settings, data, dataIndex) => {
        let result = true
        if (dataIndex === 0) logger.debug('Filtering')
        result = result && filter.filterVin(data[VIN_COLUMN])
        result = result && filter.filterUser(data[USER_COLUMN])
        result = result && filter.filterStatuses(data[STATUS_COLUMN])
        result = result && filter.filterDate(data[START_COLUMN])
        result = result && filter.filterDate(data[MODIFIED_COLUMN])
        return result
      }
    }

    /* Event binding  */
    $.fn.dataTable.ext.search.push(search())

    let onChangeDate = (prop, dayIncrement) => {
      let inc = dayIncrement //
        ? m => m.add(dayIncrement, 'day') //
        : _.identity

      return e => {
        if (prop === 'beforeDate') {
          datePickerEnd.datepicker('setStartDate', datePickerStart.datepicker('getDate'))
        }
        if (prop === 'afterDate') {
          datePickerStart.datepicker('setEndDate', datePickerEnd.datepicker('getDate'))
        }

        filter[prop] = e.date ? inc(moment(e.date)) : null
        dt.draw()
      }
    }

    datePickerStart.on('changeDate', onChangeDate('beforeDate'))
    datePickerEnd.on('changeDate', onChangeDate('afterDate', 1))

    function timingSearches() {
      var waitingTime = 500,
        timeoutHandlerRef = null,
        clearHandler = () => {
          clearTimeout(timeoutHandlerRef)
          timeoutHandlerRef = null
        },
        timingSearch = callback =>
          function () {
            if (timeoutHandlerRef) clearHandler()

            callback($(this).val())

            timeoutHandlerRef = setTimeout(function () {
              logger.debug('Redrawing ')
              dt.draw()
              clearHandler()
            }, waitingTime)
          }

      $(inputVin).keyup(timingSearch(value => (filter.vin = value.toUpperCase())))
      $(inputUser).keyup(timingSearch(value => (filter.user = value)))
    }

    timingSearches()

    $(selectStatus).change(function () {
      filter.statuses = SESSION_STATUS_MAP[$(this).val()]
      dt.draw()
    })

    $(clearFiltersButton).click(() => {
      $(inputVin).val('')
      $(inputUser).val('')
      $(inputDatePickerStart).datepicker('update', '')
      $(inputDatePickerStart).datepicker('setEndDate', new Date())
      $(inputDatePickerEnd).datepicker('update', '')
      $(selectStatus).selectpicker('val', 'all')
      filter.clear()
      dt.draw()
    })

    dt.order([4, 'desc']).draw()
  })

  const {
    events: { SESSION_RESUME, SESSION_OPEN_DETAILS },
    fields: { SERIAL, SESSION_USER, STATUS, START_TIME, MODIFIED_TIME }
  } = FeedbackConstants

  const trackSessionAction = function (eventName) {
    const row = $(this).closest('tr')

    const serial = row.children('td').eq(0).text()
    const user = row.children('td').eq(1).text()
    const status = row.children('td').eq(2).children('span').attr('data-status')
    const start = new Date(Date.parse(row.children('td').eq(3).text()))
    const modified = new Date(Date.parse(row.children('td').eq(4).text()))

    const data = {
      [SERIAL]: serial,
      [SESSION_USER]: user,
      [STATUS]: status,
      [START_TIME]: start,
      [MODIFIED_TIME]: modified
    }
    feedback.track(eventName, data)
  }

  $('[data-type="show-resume"]').click(() => trackSessionAction(SESSION_RESUME))
  $('[data-type="show-details"]').click(() => trackSessionAction(SESSION_OPEN_DETAILS))

  // end of on ready script
}

function toggleSummaryToImage(imgSrc, parent) {
  $('#summary', parent).fadeOut(function () {
    $('#image-view', parent).fadeIn()
  })
}

function toggleImageToSummary(parent) {
  $('#image-view', parent).fadeOut(function () {
    $('#summary', parent).fadeIn()
  })
}

function loadImage(imgSrc, parent) {
  $('#image-view', parent).find('img').attr('src', imgSrc)
  $('.image-panning', parent)
    .panzoom({
      $zoomIn: $('#zoom-in', parent),
      $zoomOut: $('#zoom-out', parent),
      // Pan only when the scale is greater than minScale
      panOnlyWhenZoomed: true
    })
    .panzoom('reset')
}

function loadThumbnails(parent) {
  parent.find('a.thumbnail').click(function () {
    loadImage($(this).find('img').attr('src'), parent)
    toggleSummaryToImage($(this).find('img').attr('src'), parent)
  })
}

function bindButtons(parent) {
  parent.find('.btn-back').click(() => toggleImageToSummary())
}

function showSummary(sessionId) {
  const {
    events: { SESSION_OPEN_SUMMARY },
    fields: { TYPE, PATH }
  } = FeedbackConstants
  feedback.track(SESSION_OPEN_SUMMARY, { [TYPE]: getEventData()[PATH] })

  UIUtils.setLoading()
  $('#details-modal-target').load(`/vehicle-session-summary/${sessionId}`, () => {
    loadThumbnails($('#details-modal-target'))
    bindButtons($('#details-modal-target'))
    UIUtils.clearLoadingButtons()
    $('#details-modal').modal()
  })
}

function handleDemoSessions(e) {
  window.location.assign(`vehicle-session-list${e.checked ? '?sessionType=demo' : ''}`)
}

module.exports = {
  showSummary: showSummary,
  handleDemoSessions: handleDemoSessions,
  load: load
}
