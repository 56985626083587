const STORAGE_KEY = 'eventData'

module.exports = {
  storeEventData: function (data, reset = false) {
    let storageDataStr = localStorage.getItem(STORAGE_KEY) || '{}'
    if (reset) storageDataStr = '{}'

    const storageData = JSON.parse(storageDataStr)
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        ...storageData,
        ...data
      })
    )
  },
  getEventData: function () {
    let storageDataStr = localStorage.getItem(STORAGE_KEY) || '{}'
    return JSON.parse(storageDataStr)
  }
}
