import $ from 'jquery';
import _ from 'lodash';

(function() {
  function processLinks(html, vehicleSession) {
    //TODO: Conflicts with 15.1 should remove this file from client
    return new Promise((resolve, reject) => {
      let obj = $.parseHTML(html && convertToHtml(html) || ''),
        anchors = _.chain($(obj).find('a[href]')).map((link) => $(link)).filter((link) => _.includes(link.attr('href'), '/reference/')).value();

      if (!anchors.length)
        resolve(html);


      let promises = _.map(anchors, (link) => {
        let href = link.attr('href'),
          hrefParts = href.split('/'),
          id = hrefParts[hrefParts.length - 1],
          pdf = hrefParts[hrefParts.length - 2],
          pathArray = location.href.split('/'),
          protocol = pathArray[0],
          host = pathArray[2],
          url = protocol + '//' + host;

        return new Promise((innerResolve, reject) => {
          $.get(`/related-reference/check/${id}`).then((data) => {
            link.attr('data-id', id + '.html');
            link.attr('href', 'javascript:void(0);');
            link.attr('onclick', 'TweddleId.RelatedReferenceDialog.showRelatedReference(this)');
            innerResolve();
          }).fail(() => {
            link.attr('href', `${url}/related-reference/pdf/${vehicleSession.year}/${vehicleSession.model}/${pdf}.pdf#nameddest=E.${id}`.replace(/"/g, ''));
            link.attr('target', '_blank');
            innerResolve();
          });
        });
      });

      return Promise.all(promises).then(() => resolve($('<div>').append($(obj).clone()).remove().html()));
    });
  }

  function convertToHtml(html) {
    if (!((html.trim()).slice(1).trim()).startsWith('div')) {
      html = `<div>${html}</div>`;
    }
    return html;
  }

  module.exports = {
    processLinks: processLinks
  };

})();