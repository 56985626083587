'use strict'

import $ from 'jquery'
import _ from 'lodash'
import axios from 'axios'
import logger from './logger-service'

;(function () {
  function refreshStateCascade(enableFun /* selectors of components to disabled */) {
    var elements = _.tail(arguments)
    return function () {
      let val = $(this).val()
      let ecuId = $('#inputECU')
        .find('option:contains(' + val + ')')
        .attr('ecuid')
      disableAndClean.apply(null, elements)
      if (ecuId) {
        enableFun(ecuId)
      } else if (val) {
        enableFun(val)
      }
    }
  }

  function disableAndClean() {
    _.forEach(_.toArray(arguments), function (selector) {
      if (typeof selector === 'function') {
        selector()
        return
      }
      var target = $(selector)
      $('option', target).not(':eq(0)').remove()
      target.attr('disabled', 'disabled')
      target.removeClass('loading')
    })
  }

  /*
   creates an event handler that will remove all
   the disabled attributes
   */
  function enable(/* selectors */) {
    var elements = arguments
    return function () {
      _.forEach(_.toArray(elements), function (selector) {
        $(selector).removeAttr('disabled')
      })
    }
  }

  function reenable(target) {
    target.removeAttr('disabled')
    $('option', target).not(':eq(0)').remove()
  }

  function getOption() {
    return codeLabel =>
      $('<option>', {
        value: codeLabel.code,
        text: codeLabel.label
      })
  }

  function fromCodeLabelsToOptions(selector, selectedValue) {
    return function (codeLabels) {
      var target = $(selector)
      reenable(target)
      target.append(_.map(codeLabels, getOption(selectedValue)))
    }
  }

  function errorAlert(msg) {
    var alert = $('#error-message')
    alert.find('#error-message-content').empty().append(msg)
    alert.fadeIn(1000)
  }

  function successAlert(msg) {
    var alert = $('#success-message')
    alert.find('#success-message-content').empty().append(msg)
    alert.fadeIn(1000)
  }

  function prepButtonsForLoading() {
    clearLoadingButtons()
    $('.btn.processing').each(function () {
      $(this).click(setLoading)
    })
    $(document) //
      .bind('ajaxComplete', clearLoadingButtons)
      .bind('error', clearLoadingButtons)

    axios.interceptors.response.use(_.identity, function (error) {
      clearLoadingButtons()
      return Promise.reject(error)
    })

    $('.modal').on('show.bs.modal', clearLoadingButtons)
  }

  function toggleLoading(boolVal) {
    if (boolVal) {
      setLoading()
    } else {
      clearLoadingButtons()
    }
  }

  function setLoading() {
    logger.log('Set  Loading activated')
    $('body:first').ploading({ action: 'show' })
  }

  function clearMessages() {
    $('#error-message').hide()
    $('#success-message').hide()
  }

  function clearLoadingButtons() {
    $('body:first').ploading({ action: 'hide' })
  }

  function refreshSelectpicker(selectpickerId) {
    return function () {
      // console.log('refreshing ', selectpickerId);
      $(selectpickerId).selectpicker('refresh')
    }
  }

  function contextPath(path) {
    let bodyContextPathData = $('body').data('context-path') || ''
    return bodyContextPathData + path
  }

  function handleError(err) {
    clearLoadingButtons()
    logger.error('Handling error', err)
    let message =
      _.get(err, 'response.data.error.message') ||
      _.get(err, 'message') ||
      _.get(err, 'error.message') ||
      _.get(err, 'response.data')
    errorAlert(message)
  }

  /**
   * DataTables Conditional pagination solution, conditionalPaging plugin is not suit well,
   * it operates CSS visibility, and unfortunately has no hide/show option
   * @param settings {Object} DataTables settings
   */
  function dataTablesConditionalPaginationCallback(settings) {
    var pagination = $(settings.nTableWrapper).find('.dataTables_paginate')
    if (settings._iDisplayLength >= settings.fnRecordsDisplay()) {
      pagination.hide()
    } else {
      pagination.show()
    }
  }

  function hasDragAndDropSupport() {
    var userAgent = window.navigator.userAgent

    // we don't support dragAndDrop on iPad and Samsung Galaxy Tab
    // actually dragAndDrop will not work on mobile devices either
    // so for determining Samsung Galaxy Tab /Android/i regexp will be used
    if (userAgent.match(/iPad/i) || userAgent.match(/Android/i)) {
      return false
    } else {
      return true
    }
  }

  function disableiOSBodyScrollOverModal($modalEl) {
    let scollableElement = $modalEl.find('.modal-body')[0]

    function touchstartOnElement() {
      var top = scollableElement.scrollTop,
        totalScroll = scollableElement.scrollHeight,
        currentScroll = top + scollableElement.offsetHeight

      //If we're at the top or the bottom of the containers
      //scroll, push up or down one pixel.
      //
      //this prevents the scroll from "passing through" to
      //the body.
      if (top === 0) {
        scollableElement.scrollTop = 1
      } else if (currentScroll === totalScroll) {
        scollableElement.scrollTop = top - 1
      }
    }

    function touchmoveOnElement(evt) {
      //if the content is actually scrollable, i.e. the content is long enough
      //that scrolling can occur
      if (scollableElement.offsetHeight < scollableElement.scrollHeight) evt._isScroller = true
    }

    function touchmoveOnBody(evt) {
      //In this case, the default behavior is scrolling the body, which
      //would result in an overflow.  Since we don't want that, we preventDefault.
      if (!evt._isScroller) {
        evt.preventDefault()
      }
    }

    $modalEl.on('show.bs.modal', function (e) {
      scollableElement.addEventListener('touchstart', touchstartOnElement)

      scollableElement.addEventListener('touchmove', touchmoveOnElement)

      document.body.addEventListener('touchmove', touchmoveOnBody)
    })

    $modalEl.on('hide.bs.modal', function (e) {
      scollableElement.removeEventListener('touchstart', touchstartOnElement)
      scollableElement.removeEventListener('touchmove', touchmoveOnElement)
      document.body.removeEventListener('touchmove', touchmoveOnBody)
    })
  }

  let createStringId = (separator, ...data) => _.filter(data, d => !!d).join(separator)

  let hasPermission = (code, permissionCodes) => _.includes(permissionCodes, code)

  module.exports = {
    clearLoadingButtons: clearLoadingButtons,
    clearMessages: clearMessages,
    contextPath: contextPath,
    dataTablesConditionalPaginationCallback: dataTablesConditionalPaginationCallback,
    disableAndClean: disableAndClean,
    enable: enable,
    errorAlert: errorAlert,
    fromCodeLabelsToOptions: fromCodeLabelsToOptions,
    handleError: handleError,
    prepButtonsForLoading: prepButtonsForLoading,
    refreshSelectpicker: refreshSelectpicker,
    refreshStateCascade: refreshStateCascade,
    setLoading: setLoading,
    successAlert: successAlert,
    toggleLoading: toggleLoading,
    hasDragAndDropSupport: hasDragAndDropSupport,
    disableiOSBodyScrollOverModal: disableiOSBodyScrollOverModal,
    createStringId: createStringId,
    hasPermission: hasPermission
  }
})()
