import $ from 'jquery'
import logger from './logger-service'

function load() {
  // Bind Regenerate buttons to update the user token
  $('.regenButton').click(function (e) {
    e.preventDefault()
    var posting = $.post(window.location.pathname + '/regenerate-user-token', {})

    posting.done(function (newToken) {
      logger.debug('New token', newToken)
      $('#inputUserToken').val(newToken)
    })
  })
  $('#removeButton').click(e => {
    logger.debug('Calling delete in', window.location.pathname)
    $.ajax({
      url: window.location.pathname,
      type: 'DELETE',
      dataType: 'json',
      success: function (result) {
        window.location = '/admin/users'
        logger.debug('Remove button result', result)
      },
      fail: function (err) {
        logger.error(err)
      }
    })
  })

  //keep from auto completing
  $('#inputPassword').attr('type', 'text').val('')
}

module.exports = {
  load: load
}
