import $ from 'jquery'
import axios from 'axios'

let timeHandler

const toggleStatus = (removeClass, addClass, statusLabel, batteryLabel) => {
  $('#vehicle-state .vehicle-state-item.connection-status')
    .removeClass(removeClass)
    .addClass(addClass)
    .html(statusLabel)
  $('#vehicle-state .vehicle-state-item.battery-voltage')
    .removeClass(removeClass)
    .addClass(addClass)
    .html(`${batteryLabel} Volts`)
}

let consult = vehicleSession => () => {
  console.log('vehicle session', vehicleSession)
  let url = `/scan-inquiry/${vehicleSession._id}/battery`

  axios
    .get(url)
    .then(response => {
      console.log('Final Response', response)
      toggleStatus('disabled', 'enabled', 'Connected', response.data.calculatedValue)
    })
    .catch(err => {
      toggleStatus('enabled', 'disabled', 'Disconnected', '0.00')
      clearInterval(timeHandler)
    })
}

function load(vehicleSession) {
  if (!vehicleSession?.scanToolSessionId) return

  timeHandler = setInterval(consult(vehicleSession), 10000)
  consult(vehicleSession)()
}

module.exports = {
  load: load,
  toggleStatus: toggleStatus
}
