import $ from 'jquery'
import logger from './logger-service'

function load() {
  $('#removeButton').click(e => {
    logger.debug('Calling delete in', window.location.pathname)
    $.ajax({
      url: window.location.pathname,
      type: 'DELETE',
      dataType: 'json',
      success: function (result) {
        window.location = '/admin/scan-tools'
      },
      fail: function (err) {
        logger.error(err)
      }
    })
  })
  $('#userDropDown').change(function () {
    let userInfo = JSON.parse($(this).val())
    $('#inputUserToken').val(userInfo.userToken)
    $('#inputDealer').val(userInfo.dealer)
  })
}

module.exports = {
  load: load
}
