import $ from 'jquery';

function load() {
  $('#button-processing-report').click((e) => {
    let year = $('input#inputYear').val().trim(),
      model = $('input#inputModel').val().trim();
    console.log('input:   ', year, model);
    window.location.href = location.protocol + 'related-content-report?year=' + year + '&model=' + model;
  });
}

module.exports = {
  load: load
};