import $ from 'jquery'
import { setLoading, handleError, clearLoadingButtons, successAlert, errorAlert, clearMessages } from './ui-utils'
import axios from 'axios'
import logger from './logger-service'

function load() {
  let pathname = window.location.pathname.replace(/\/\s*$/, '')
  logger.info('Pathname:', pathname)

  $('#export-button').click(function () {
    clearMessages()

    $('div#progress-bar').width(0)
    $('#progress-bar').parent().show()
    $('#export-button').attr('disabled', 'disabled')
    $('#progress-info').show().html(`Starting process.`)

    axios
      .post(pathname, {})
      .then(obj => {
        window.location = `/admin/jobs`
      })
      .catch(handleError)
  })
}

module.exports = {
  load: load
}
