import $ from 'jquery';

require('jscrollpane');
require('jquery-mousewheel');

var scrollBoxes;

$(() => {
  var throttleTimeout;

  refresh();

  // resize all custom scrolls on window resize
  $(window).bind('resize', function() {
    if (!throttleTimeout) {
      throttleTimeout = setTimeout(function() {
        scrollBoxes.each(function() {
          $(this).data('jsp').reinitialise();
        });
        throttleTimeout = null;
      }, 50);
    }
  });
});

function refresh() {
  scrollBoxes = $('.scroll-box').jScrollPane({
    autoReinitialise: true,
    verticalGutter: 10
  });
}

module.exports = {
  refresh: refresh
};
