module.exports = {
  events: {
    SELECT_EQUIPMENT: 'Select Equipment',
    SYMPTOM_SEARCH: 'Symptom Search',
    SYMPTOM_START_DIAGNOSTIC: 'Symptom Start Diagnostic',
    //DTC
    DTC_UPDATE: 'DTC Update',
    DTC_ADD: 'DTC Add',
    DTC_REMOVE: 'DTC Remove',
    DTC_START_DIAGNOSTIC: 'DTC Start Diagnostic',
    //  session
    SESSION_CANCEL: 'Session Cancel',
    SESSION_PAUSE: 'Session Pause',
    SESSION_RESUME: 'Session Resume',
    SESSION_OPEN_DETAILS: 'Session Open Details',
    SESSION_SELECT_SOLUTION: 'Session Select Solution',
    SESSION_OPEN_RELATED_CONTENT: 'Session Open Related Content',
    SESSION_FINISH: 'Session Finish',
    SESSION_OPEN_SUMMARY: 'Session Open Summary',
    SESSION_START_ANOTHER: 'Session Start Another',
    SESSION_EXPORT_SUMMARY: 'Session Export Summary',
    SESSION_SELECT_HISTORY: 'Session Select Diagnostic Test History'
  },
  fields: {
    PATH: 'Path',
    TYPE: 'Type', //'DTC|Symptom|Guide|Solution',
    DTC: 'DTC',
    SYMPTOM: 'Symptom',
    SOLUTION: 'Solution',
    SERIAL: 'Serial Number',
    TERM: 'term',
    PROCEDURE: 'Procedure',
    DESCRIPTION: 'Description',
    RANK: 'Rank',
    SESSION_USER: 'Session User',
    STATUS: 'Status',
    START_TIME: 'Start Time',
    MODIFIED_TIME: 'Modified Time',
    META: 'Meta',
    ITERATE_LOG_ITEMS: 'iterate_log_items',
    ECU: 'ECU'
  }
}
