import $ from 'jquery';
import { processLinks } from './link-processor';

(function IIFE() {


  function showRelatedReference(button) {
    window.event.preventDefault();
    $('.modal.in').modal('hide');
    let id = $(button).attr('data-id'),
      dialogId = `#details-modal-${id}`;

    if (!$('#dialogs', $('body')).length)
      $('<div id="dialogs"></div>').appendTo($('body'));

    $.get(`/related-reference/dialog/${id}`, (data) => {
      $('#dialogs', $('body')).empty().append($(data)).ready(() => {
        load();
        $(dialogId).show();
      });
    }, 'html');
  }

  function load() {
    let src = $('.content').data('src');
    if (!src) return;
    $.get(src, (data) => {
      $('.header-title').html(getTitle(data, src));
      processLinks(data).then((html) => {
        $('.content').html(html);
      });
    }).fail(() => {
      $('.content').html('Related reference not found.');
    });
  }

  function getTitle(html, src) {
    let obj = $.parseHTML(html),
      title = $(obj).find('.title').html();
    return title ? title : src;
  }

  module.exports = {
    showRelatedReference: showRelatedReference
  };
})();