export default function() {
  return {
    errorElement: 'div',
    errorClass: 'error-input',
    errorPlacement: function(error, element) {
      error.addClass('error-text');
      element.parent().addClass('input-validate-wrapper error');
      $('<i class="material-icons">error</i>').insertAfter(element);
      element.parent().append(error);
    },
    success: function(error, element) {
      $(element).parent().find('i').detach();
      $(element).parent().removeClass('input-validate-wrapper');
      error.remove();
    }

  };
}