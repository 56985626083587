'use strict';
import axios from 'axios';
import $ from 'jquery';
import mustache from 'mustache';


let vehicleInfoConfigurationModalTemplate = null;

function lazyLoadTemplate() {
  console.log('inside show relate content');
  if (!vehicleInfoConfigurationModalTemplate) {
    vehicleInfoConfigurationModalTemplate = $('#vehicleInfoConfigurationModalTemplate').html();
    mustache.parse(vehicleInfoConfigurationModalTemplate);
  }
}

function updateVehicleInformationConfiguration(vehicleSessionId, ecu, configuration) {
  return new Promise((resolve, reject) => {
    axios.put(`/vehicle-session-info/${vehicleSessionId}/config`, {
        ecu: encodeURIComponent(ecu),
        configuration: configuration
      }).then((res)=>res.data)
      .then((vs) => {
        console.log('updateVehicleInformationConfiguration', vs);
        toggleLink(vs.configurations, vs._id);
        resolve(vs);
      }).catch(reject);
  });

}

let modifyVehicleConfiguration = (vehicleSessionId) => () => {
  axios.get(`/vehicle-session-info/${vehicleSessionId}/config`).then((res) => res.data)
    .then((configurations) => {
      lazyLoadTemplate();
      $('#vehicleInfoConfigurationModalTarget')
        .html(mustache.render(vehicleInfoConfigurationModalTemplate, { configurations: configurations }))//
        .ready(() => {
          $('#vehicleInfoConfigurationModal .btn-remove').click(removeConfigurationClick(vehicleSessionId));
          $('#vehicleInfoConfigurationModal ').modal();


        });

    });
};
let removeConfigurationClick = (vehicleSessionId) => function() {
  let ecu = encodeURIComponent($(this).attr('data-ecu')),
    parent = $(this).closest('tr');

  axios.delete(`/vehicle-session-info/${vehicleSessionId}/config/${ecu}`).then((res) => res.data)
    .then((configurations) => {
      parent.detach();

      if (_.isEmpty(configurations)) {
        $('#vehicleInfoConfigurationModal ').modal('hide');
        toggleLink(configurations, vehicleSessionId);
      }
    });


}
let toggleLink= (configurations, vsId)=> {
  if (_.isEmpty(configurations)) {
    $('a#vehicle-session-info-config').addClass('hidden');
  } else {
    $('a#vehicle-session-info-config').removeClass('hidden');
    $('a#vehicle-session-info-config').off('click').click(modifyVehicleConfiguration(vsId));
  }
};
let load = (vs) => {
  toggleLink(vs.configurations,  vs._id);
};

module.exports = {
  updateVehicleInformationConfiguration: updateVehicleInformationConfiguration,
  load: load
};