import Map from 'es6-map'
require('array.from').shim()
import $ from 'jquery'
import _ from 'lodash'
import { setLoading, handleError, clearLoadingButtons, successAlert, errorAlert, clearMessages } from '../ui-utils'

import axios from 'axios'
import mustache from 'mustache'
import moment from 'moment'
import {
  disableAndClean,
  enable,
  fromCodeLabelsToOptions,
  refreshStateCascade,
  prepButtonsForLoading,
  refreshSelectpicker,
  contextPath
} from '../ui-utils'

const STATUS_STYLES = new Map([
  ['inactive', 'status-active'],
  ['active', 'status-active'],
  ['complete', 'status-complete'],
  ['failed', 'status-suspend']
])
const STATUSES_TO_LABEL = new Map([
    ['failed', 'Failed/Canceled'],
    ['complete', 'Finished'],
    ['active', 'In Progress'],
    ['inactive', 'In Queue']
  ]),
  LABEL_TO_STATUS = new Map(_.map(Array.from(STATUSES_TO_LABEL.entries()), keyValue => [keyValue[1], keyValue[0]]))

let parsedTemplates

function loadList() {
  loadStates()
  loadTypes()
  loadTemplates()
  fetchListData().then(bindList)
}

function loadDetails(jobId) {
  loadTemplates()
  fetchDetailData(jobId)
    .then(applyTemplate('jobsDetail'))
    .then(setProgressFetch(jobId, [{ id: 'progressUpdate' }, { id: 'logUpdate' }]))
    .then(() => bindDetails(jobId))
}

function loadStates() {
  var jobStateFilter = $('#job-state-filter').find('option').remove().end()

  jobStateFilter.append('<option value="all">All</option>')

  axios
    .get('/api/stats', {})
    .then(obj => obj.data)
    .then(data =>
      _.chain(_.keys(data))
        .filter(k => data[k])
        .filter(c => _.includes(c, 'Count'))
        .map(s => s.replace('Count', ''))
        .value()
    )
    .then(items => _.each(items, i => jobStateFilter.append(`<option value="${i}">${i}</option>`)))
    .then(items => _.map(items, item => ({ code: item, label: STATUSES_TO_LABEL.get(item) })))
    .then(codeLabels => fromCodeLabelsToOptions('#job-state-filter')(codeLabels))
    .then(refreshSelectpicker('#job-state-filter'))

  // .then(jobStateFilter.selectpicker('refresh'));
}

function loadTypes() {
  var jobTypeFilter = $('#job-type-filter').find('option').remove().end().append('<option value="all">All</option>')

  axios
    .get('/api/job/types', {})
    .then(obj => obj.data)
    .then(items => _.each(items, i => jobTypeFilter.append(`<option value="${i}">${i}</option>`)))
    .then(refreshSelectpicker('#job-type-filter'))
}

function bindList() {
  $('#job-order').change(fetchListData)
  $('#job-type-filter').change(fetchListData)
  $('#job-state-filter').change(fetchListData)
}

function bindDetails(jobId) {
  $('button.cancel').click(cancelJob(jobId))
}
let cancelJob = jobId => () => {
  axios
    .put(`/admin/jobs/${jobId}/cancel`)
    .then(() => loadDetails(jobId))
    .catch(handleError)
}

let progressHandle = {}
//,count = 1;
let setProgressFetchForList = payload => {
  _.chain(payload.items)
    .filter(item => progressHandle[item.id])
    .each(item => {
      window.clearInterval(progressHandle[item.id])
      delete progressHandle[item.id]
    })
    .value()

  _.chain(payload.items)
    .filter(item => parseInt(item.progress) !== 100)
    .each(item => setProgressFetch(item.id, [{ id: 'row', target: `rowTarget${item.id}` }])(item))
    .value()
}
let setProgressFetch = (jobId, templates) => model => {
  // if (model.state === 'active') {

  // }

  progressHandle[jobId] = window.setInterval(() => {
    fetchDetailData(jobId)
      .then(model => {
        // console.log(model.progress, model.state);
        if (parseInt(model.progress) === 100 || model.state === 'failed') {
          // console.log('clearing interval');
          window.clearInterval(progressHandle[jobId])
        }
        return model
      })
      .then(model => _.each(templates, template => applyTemplate(template.id, template.target)(model)))
  }, 5000)
}

function loadTemplates() {
  parsedTemplates = _.chain($('script.template'))
    .map(template => $(template).attr('id'))
    .reduce((memo, templateId) => {
      let html = $(`#${templateId}`).html()
      mustache.parse(html)
      return memo.set(templateId, html)
    }, new Map())
    .value()
}

function fetchDetailData(jobId) {
  let fetches = [
    axios.get(`/api/job/${jobId}`).then(obj => obj.data),
    axios.get(`/api/job/${jobId}/log`).then(obj => ({ logs: obj.data }))
  ]

  return (
    Promise.all(fetches)
      .then(items => {
        return items
      })
      // .then((items) => _.map(items, 'data'))
      .then(items => _.reduce(items, (memo, item) => _.merge(memo, item), {}))
      .then(decorateDetail)
      // .then((item) => ({ item: item }))
      .catch(handleError)
  )
}

let debug = prefix => obj => {
  // console.log(prefix, JSON.stringify(obj));
  return obj
}

let fetchListData = () =>
  axios
    .get('/api/stats', {})
    .then(obj => obj.data)
    .then(data =>
      _.keys(data)
        .filter(c => _.includes(c, 'Count'))
        .map(s => ({ state: s.replace('Count', ''), value: data[s] }))
        .filter(item => item.value)
    )
    .then(items => _.map(items, i => ({ state: i.state, count: i.value })))
    .then(fetchPayload => _.map(fetchPayload, i => `/api/jobs/${i.state}/0..${i.count}/desc`))
    .then(urls => _.map(urls, url => axios.get(url)))
    .then(axiosGets => Promise.all(axiosGets))
    .then(items => _.map(items, 'data'))
    .then(items => _.flatten(items))
    .then(decorateList)
    .then(sortList)
    .then(applyFilters)
    .then(items => ({ items: items }))
    .then(applyTemplate('jobsList'))
    .then(setProgressFetchForList)
    .catch(handleError)

let applyFilters = items => {
  let currentType = $('#job-type-filter').val(),
    currentState = $('#job-state-filter').val(),
    allFilter = i => true,
    typeFilter = i => i.type === currentType,
    stateFilter = i => i.state === currentState,
    appliedTypeFilter = !currentType || currentType === 'all' ? allFilter : typeFilter,
    appliedStateFilter = !currentState || currentState === 'all' ? allFilter : stateFilter

  return _.chain(items).filter(appliedTypeFilter).filter(appliedStateFilter).value()
}
let sortList = items => {
  let descComparator = i => parseInt(i.updated_at) * -1,
    ascComparator = i => parseInt(i.updated_at),
    comparators = new Map().set('asc', ascComparator).set('desc', descComparator),
    choosenComparator = comparators.get($('#job-order').val()) || descComparator
  return _.sortBy(items, choosenComparator)
}
let decorateDetail = item => {
  item.createdDate = moment(new Date(parseInt(item.started_at))).format('MM/DD/YYYY hh:mm:ss A')
  item.updatedDate = moment(new Date(parseInt(item.updated_at))).format('MM/DD/YYYY hh:mm:ss A')
  item.Username = _.get(item, 'data.Payload.Username') || _.get(item, 'data.Username') || 'System'
  item.status = STATUS_STYLES.get(item.state) || STATUS_STYLES.get('failed')
  item.statusLabel = STATUSES_TO_LABEL.get(item.state) || STATUSES_TO_LABEL.get('failed')
  item.displayCancelButton = item.state === 'active' || item.state === 'inactive'
  return item
}
let decorateList = items => _.each(items, decorateDetail)

let applyTemplate = (templateName, templateTarget) =>
  !parsedTemplates.get(templateName)
    ? _.identity
    : model => {
        let viewTemplate = parsedTemplates.get(templateName),
          viewTarget = $(templateTarget ? `#${templateTarget}` : `#${templateName}Target`),
          parsedHtml = mustache.render(viewTemplate, model)
        viewTarget.html(parsedHtml)
        return model
      }

module.exports = {
  loadList: loadList,
  loadDetails: loadDetails
}
