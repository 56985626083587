import $ from 'jquery';
import mustache from 'mustache';
import axios from 'axios';
import {updateVehicleInformationConfiguration} from './vehicle-info-config';

let selectEcuConfigurationModalTemplate = null;


function showConfigurationSelectionRelatedContentModal(data, callback) {
  let objects = _.map(data.configurationNames, (e) => codeLabel(e, e)),
    modalData = { configurationNames: objects };
  showConfigurationModal(modalData, callback);
}

let codeLabel = (code, label) => ({ code: code, label: label });

function showConfigurationModal(data, callback) {
  console.log('inside show relate content');
  if (!selectEcuConfigurationModalTemplate) {
    selectEcuConfigurationModalTemplate = $('#selectEcuConfigurationModalTemplate').html();
    mustache.parse(selectEcuConfigurationModalTemplate);
  }

  $('#selectEcuConfigurationModalTarget').html(mustache.render(selectEcuConfigurationModalTemplate, data));

  $('#selectEcuConfigurationModal #buttonConfigSelect').click(() => {
    let selected = $('#selectEcuConfigurationModal input[name="configuration"]:checked').val(),
      selectedLabel = $('#selectEcuConfigurationModal input[name="configuration"]:checked').attr('data-label');

    if (!selected) return;

    $('#selectEcuConfigurationModal ').modal('hide');

    callback(selected, selectedLabel);

  });

  $('#selectEcuConfigurationModal ').modal();


}


function showConfigurationSelectionForTree(vehicleSessionId, data, callback) {
  let ecu = _.get(data, '[0]ECU'),
   returnCall = (code, configurationName)=>{
    updateVehicleInformationConfiguration(vehicleSessionId, ecu, configurationName)
      .then(()=> callback(code, configurationName));
  };
  console.log('go devebug me');
  let objects = _.map(data, (e) => codeLabel(e.DecisionTree, e.ConfigName)),
    modalData = { configurationNames: objects };
  showConfigurationModal(modalData, returnCall);
}

function inquiryConfigNames(vehicleSessionId, ecu, dtc, callbackFromModal) {
  let returnCall = (code, configurationName)=>{
    updateVehicleInformationConfiguration(vehicleSessionId, ecu, configurationName).then(()=> callbackFromModal(code, configurationName));
  };

  axios.get(`/related-content/inquiry-config-names/${vehicleSessionId}/${ecu}/${dtc}`)
    .then((res) => res.data)
    .then((data) =>
      _.size(data.configurationNames) > 1 ?
        showConfigurationSelectionRelatedContentModal(data, returnCall) :
        callbackFromModal(_.first(data.configurationNames),null)
    ).catch((err) => console.error(err));

}
module.exports = {
  showConfigurationSelectionForTree: showConfigurationSelectionForTree,
  inquiryConfigNames: inquiryConfigNames
};