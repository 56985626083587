import _ from 'lodash'
import $ from 'jquery'

const inputDatePickerStart = '#datepickerStart'
const inputDatePickerEnd = '#datepickerEnd'

const DATEPICKER_OPTIONS = {
  format: {
    toDisplay: function(date, format, language) {
      let d = new Date(date)
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
      return d.toLocaleDateString('en-US')
    },
    toValue: function(date, format, language) {
      let d = new Date(date)
      return d.toUTCString()
    }
  },
  templates: {
    leftArrow: '<i class="material-icons">keyboard_arrow_left</i>',
    rightArrow: '<i class="material-icons">keyboard_arrow_right</i>'
  },
  autoclose: true,
  todayHighlight: true,
  toggleActive: true,
  todayBtn: 'linked',
  clearBtn: true
};

function datePickerOptions(obj) {
  return _.merge({}, DATEPICKER_OPTIONS, obj);
}

function submit(url) {
  $.ajax({
    url:url,
    type:'post',
    data:$('#dateRangeForm').serialize(),
    success: function(data){
      window.open(encodeURI(`data:text/csv;charset=utf-8,${data}`))
    },
    error: function(xhr) {
      $('#error-message').css({display: 'block'})
      $('#error-message-content').text(xhr.responseText)
    }

  });
}

function load() {
  $(inputDatePickerStart).datepicker(
    datePickerOptions({
      title: 'Start date',
      container: inputDatePickerStart,
      orientation: 'auto left',
      endDate: new Date()
    })
  );
  $(inputDatePickerEnd).datepicker(
    datePickerOptions({
      title: 'End date',
      container: inputDatePickerEnd
    })
  );
}

module.exports = {
  load: load,
  submit: submit
}
