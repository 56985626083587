import $ from 'jquery';
import axios from 'axios';

function load(vehicleSession) {
  // console.log('inside basic-inspection');
  // axios.get(`/basic-inspection/${vehicleSession.year}/L33/${vehicleSession.make}/${vehicleSession.model}`)
  //   .then((payload) => payload.data.url)
  //   .then((url) => {
  //     console.log('Basic Inspection model', vehicleSession.model, 'year', vehicleSession.year, url);

  //     let button = $('a.vehicle-state-item.basic-inspection');
  //     button.removeClass('hidden');
  //     button.attr('href', url);
  //   });
}

module.exports = {
  load: load
};