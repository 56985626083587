import $ from 'jquery'
import _ from 'lodash'
import axios from 'axios'
import { successAlert, errorAlert } from '../ui-utils'
import logger from '../logger-service'

const addSelectedRolesButton = '#add-selected-roles',
  removeSelectedRolesButton = '#remove-selected-roles',
  availableRoles = '#availableRoles',
  assignedRoles = '#assignedRoles',
  selectUser = '#select-user',
  selectRole = '#select_role',
  addSelectedPermissionsButton = '#add_permissions',
  removeSelectedPermissionsButton = '#remove_permissions',
  availablePermissions = '#available_permissions',
  assignedPermissions = '#assigned_permissions',
  saveUserRolesButton = '#save-user-roles',
  saveRolePermissionsButton = '#save-role-permissions',
  addNewRoleButton = '#add-new-role',
  rolesList = '#roles-list',
  PERMISSIONS = [
    'Import DTC List',
    'Nissan Transformation',
    'BI Export',
    'Related Content Inquiry',
    'Vehicle Simulator',
    'User Management',
    'Vehicle Sessions'
  ]

;(function IIFE() {
  let pathname = window.location.pathname.replace(/\/\s*$/, '')

  /**
   *
   * @param button add or remove options
   * @param sectionFrom section from which roles/permissions move to other side
   * @param sectionTo section to which roles/permissions move into
   */
  function addSelectedOption(button, sectionFrom, sectionTo) {
    $(button).click(function (e) {
      e.preventDefault()
      const selectedOpts = $(sectionFrom).find('option:selected')
      $(sectionTo).append($(selectedOpts).clone())
      $(selectedOpts).remove()
    })
  }

  let handleUserChange = function (users, roles) {
    $(selectUser).change(function () {
      const user = getSelectedUser(users)
      if (user) {
        let unassignedRoles = _.difference(
          _.map(roles, r => r.role),
          _.get(user, 'roles', [])
        )
        removeAllOptions(availableRoles, assignedRoles)
        appendUnassignedToAvailableOptions(availableRoles, unassignedRoles)
        appendAssignedOptions(user.roles, assignedRoles)
      }
    })
  }

  function handleRoleChange(roles) {
    $(selectRole).change(function () {
      let role = getSelectedRole(roles)
      if (role) {
        let unassignedPermissions = _.difference(PERMISSIONS, _.get(role, 'permissions', []))
        removeAllOptions(availablePermissions, assignedPermissions)
        appendUnassignedToAvailableOptions(availablePermissions, unassignedPermissions)
        appendAssignedOptions(role.permissions, assignedPermissions)
      }
    })
  }

  function getSelectedUser(users) {
    let selectedUser = $(selectUser).find('option:selected').val()
    return _.find(users, u => u.username === selectedUser)
  }

  function getSelectedRole(roles) {
    let selectedRole = $(selectRole).find('option:selected').val()
    return _.find(roles, r => r.role === selectedRole)
  }

  let appendAssignedOptions = function (assignedOptions, assignedElement) {
    _.forEach(assignedOptions || [], value => {
      $(assignedElement).append($('<option></option>').attr('value', value).text(value))
    })
  }

  let removeAllOptions = function (available, assigned) {
    $(available).children('option').remove()
    $(assigned).children('option').remove()
  }

  let appendUnassignedToAvailableOptions = function (available, unassigned) {
    _.forEach(unassigned, function (value) {
      $(available).append($('<option></option>').attr('value', value).text(value))
    })
  }

  let getAssignedRolesAndSelectedUser = function (users) {
    const roles = [],
      selectedUser = getSelectedUser(users)
    $(`${assignedRoles} option`).each(function () {
      roles.push($(this).val())
    })
    return { roles, selectedUser }
  }

  function updateUserRoles(users) {
    $(saveUserRolesButton).click(function (e) {
      e.preventDefault()
      let { roles, selectedUser } = getAssignedRolesAndSelectedUser(users)
      if (selectedUser) {
        axios
          .post(`${pathname}/update`, {
            roles: roles,
            selectedUser: selectedUser
          })
          .then(() => successAlert(`User roles updated successfully.`))
          .catch(error => logger.error(error))
      } else {
        errorAlert('Please select user to display roles.')
      }
    })
  }

  let getAssignedPermissionsAndSelectedRole = function (roles) {
    let permissions = [],
      selectedRole = getSelectedRole(roles)
    $(`${assignedPermissions} option`).each(function () {
      permissions.push($(this).val())
    })
    return { permissions, selectedRole }
  }

  function updateRolePermissions(roles) {
    $(saveRolePermissionsButton).click(function (e) {
      e.preventDefault()
      let { permissions, selectedRole } = getAssignedPermissionsAndSelectedRole(roles)
      if (selectedRole) {
        axios
          .post(`${pathname}/update-permissions`, {
            permissions: permissions,
            role: selectedRole
          })
          .then(res => successAlert(`User permissions updated successfully`))
          .catch(error => logger.error(error))
      } else {
        errorAlert('Please select role to save permissions.')
      }
    })
  }

  function createNewRole(roles) {
    $(addNewRoleButton).click(function () {
      let newRole = $('#new-role').val()
      if (newRole) {
        if (!isDuplicate(rolesList, newRole)) {
          appendNewRoleToList(newRole)
          return saveNewRole(newRole)
        } else {
          errorAlert(`Role already exists`)
        }
      }
    })

    $(rolesList).on('click', '.remove-role', function () {
      let role = $(this).closest('li').text()
      if (role !== 'administrator' && role !== 'admin') {
        removeSelectedRole(roles, role)
        $(this).closest('li').remove()
        successAlert(`${role} has been removed.`)
      } else {
        errorAlert(`${role} can not be removed.`)
      }
    })
  }

  function isDuplicate(ul, value) {
    let listItems = $(`${ul} li`)
    return _.some(listItems, item => item.innerText.toLowerCase() === value.toLowerCase())
  }

  function saveNewRole(role) {
    return axios.post(`${pathname}`, { role: role }).then(() => location.reload())
  }

  let appendNewRoleToList = function (newRole) {
    $(rolesList).append(
      $(
        `<li>${newRole}<button style="width: 30px;height: 25px" class="remove-role icon-button id-icons remove-icon btn pull-right"></button></li>`
      )
        .addClass('list-group-item')
        .addClass('form-control')
        .css('margin-top', '5px')
    )
  }

  function removeSelectedRole(roles, role) {
    let selectedRole = _.find(roles, r => r.role === role)
    axios.delete(`${pathname}/${selectedRole._id}/remove`)
  }

  let roleToUserMapping = function (users, roles) {
    addSelectedOption(addSelectedRolesButton, availableRoles, assignedRoles)
    addSelectedOption(removeSelectedRolesButton, assignedRoles, availableRoles)
    handleUserChange(users, roles)
    updateUserRoles(users)
  }

  let permissionToRoleMapping = function (roles) {
    addSelectedOption(addSelectedPermissionsButton, availablePermissions, assignedPermissions)
    addSelectedOption(removeSelectedPermissionsButton, assignedPermissions, availablePermissions)
    handleRoleChange(roles)
    updateRolePermissions(roles)
  }

  function load(users, roles) {
    roleToUserMapping(users, roles)
    permissionToRoleMapping(roles)
  }

  module.exports = {
    load: load,
    createNewRole: createNewRole
  }
})()
